// src/redux/userService.js

// Supondo que você deseja usar esta função
export const getUserIdFromFirebaseId = (firebaseId) => {
    // Implemente a lógica da função aqui
    return fetch(`/api/getUserId/${firebaseId}`)
      .then(response => response.json())
      .then(data => data.userId)
      .catch(error => {
        console.error('Erro ao obter o ID do usuário:', error);
        throw error;
      });
  };
  
  // Outras exportações
  export const getUserById = async (id) => {
    try {
      const response = await fetch(`/api/users/${id}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('Failed to fetch user:', error);
      throw error;
    }
  };
  
  export const updateUser = async (id, userData) => {
    try {
      const response = await fetch(`/api/users/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('Failed to update user:', error);
      throw error;
    }
  };
  