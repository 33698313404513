import React from 'react';
import { Link } from 'react-router-dom';
import './ProductDetail.css'; // Certifique-se de que o caminho está correto

const RelatedProducts = ({ relatedProducts }) => {
  if (!relatedProducts || relatedProducts.length === 0) {
    return <p>Não há produtos relacionados.</p>;
  }

  return (
    <div className="related-products">
      <h2>Mais Produtos</h2>
      <div className="related-carousel">
        {relatedProducts.map((prod) => (
          <div className="related-product-item" key={prod.id}>
            <Link to={`/produto/${prod.id}`} className="">
              <img
                src={prod.image ? `https://vidapet.tech/MARKET/Produtos/${prod.image}` : 'path/to/default-image.png'}
                alt={prod.name}
                className="related-product-image"
              />
              <div className="related-product-info">
                <h3 className="related-product-name">{prod.name}</h3>
                <p className="related-product-price">R${parseFloat(prod.price).toFixed(2)}</p>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RelatedProducts;
