// LojaDetalhes.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './LojaDetalhes.css';
import ProductList from './Produto/ProductList';

function LojaDetalhes() {
  const { id } = useParams();
  const [loja, setLoja] = useState(null);
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function loadLoja() {
      try {
        const response = await fetch(`https://vidapet.tech/MARKET/API/mostrar_loja.php?id=${id}`);
        if (!response.ok) {
          throw new Error('Erro na requisição');
        }
        const data = await response.json();
        if (Array.isArray(data) && data.length > 0) {
          setLoja(data[0]);
        } else {
          setError('Nenhum dado encontrado.');
        }
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
        setError('Erro ao carregar dados.');
      }
    }

    async function loadProducts() {
      try {
        const response = await fetch(`https://vidapet.tech/MARKET/API/listar_produtos.php?lojas_id=${id}`);
        if (!response.ok) {
          throw new Error('Erro na requisição');
        }
        const data = await response.json();
        if (data.success) {
          setProducts(data.products);
        } else {
          setError('Nenhum produto encontrado.');
        }
      } catch (error) {
        setError('Erro ao carregar produtos.');
      }
    }

    loadLoja();
    loadProducts();
  }, [id]);

  if (error) return <p>{error}</p>;
  if (!loja) return <p>Loading...</p>;

  return (
    <div className="loja-detalhes">
      {/* Banner com a logo da loja */}
      <heade className="banner">
        <img src={`https://vidapet.tech/MARKET/${loja.foto}`} alt={loja.name_loja} />
      </heade>
      
      <div className="content">
        {/* Coluna de Filtros */}
        <aside className="filters">
          <h2>Filtros</h2>
          <div className="filter-section">
            <h3>Categorias</h3>
            {/* Liste suas categorias aqui */}
            <ul>
              <li>Eletrônicos</li>
              <li>Roupas</li>
              <li>Acessórios</li>
            </ul>
          </div>
          <div className="filter-section">
            <h3>Marcas</h3>
            {/* Liste suas marcas aqui */}
            <ul>
              <li>Marca A</li>
              <li>Marca B</li>
              <li>Marca C</li>
            </ul>
          </div>
          <div className="filter-section">
            <h3>Valor</h3>
            {/* Adicione opções de filtro de valor aqui */}
            <p>Filtro de valor não implementado</p>
          </div>
        </aside>

        {/* Coluna de Produtos */}
        
        <main className="products">
          <h2>Produtos</h2>
          <div className="product-list">


          <ProductList products={products} />

          </div>
        </main>
      </div>
    </div>
  );
}

export default LojaDetalhes;
