import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// Estilos com styled-components para web
const Container = styled.div`
  margin: 20px 0 20px 20px;
`;

const Header = styled.div`
  display: ${props => props.display ? 'flex' : 'none'};
`;

const Title = styled.h2`
  font-size: 23px;
  font-weight: bold;
`;

const RestaurantList = styled.div`
  margin-top: 10px;
`;

const Item = styled(Link)`
  display: flex;
  flex-direction: row;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 5px;
  margin-right: 15px;
  text-decoration: none;
  color: inherit;
`;

const ItemImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 30px;
`;

const ItemInfo = styled.div`
  margin-left: 15px;
`;

const ItemTitle = styled.p`
  font-weight: bold;
  color: #333;
`;

const ItemDescription = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 3px;
  align-items: center;
`;

const Star = styled.span`
  font-size: 14px;
  color: #FF7C01;
`;

const Categories = styled.span`
  font-size: 14px;
  color: #999;
`;

const Distance = styled.span`
  font-size: 14px;
  color: #999;
`;

const Delay = styled.span`
  margin-top: 15px;
  font-size: 14px;
  color: #999;
`;

function Lojas({ title, display }) {
  const [restaurants, setRestaurants] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function loadRestaurants() {
      try {
        const response = await fetch('https://vidapet.tech/MARKET/API/api_loja.php');
        if (!response.ok) {
          throw new Error('Erro na requisição: ' + response.statusText);
        }
        const data = await response.json();
        setRestaurants(data);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
        setError('Erro ao carregar dados. Tente novamente mais tarde.');
      }
    }
    loadRestaurants();
  }, []);
  

  return (
    <Container>
      <Header display={display}>
        <Title>{title}</Title>
      </Header>

      {error ? (
        <p>{error}</p>
      ) : (
        <RestaurantList> 
          {restaurants.length > 0 ? (
            restaurants.map(item => (
              <Item to={`/lojas/${item.id}`} key={item.id}>
                <ItemImage src={`https://vidapet.tech/MARKET/${item.foto}`} alt={item.name_loja} />
                <ItemInfo>
                  <ItemTitle>{item.name_loja}</ItemTitle>
                  <ItemDescription>
                    <Categories>{item.description}</Categories> 
                    <Distance>{item.telefone}</Distance>
                  </ItemDescription>
                  <Distance>{item.endereco}</Distance>
                </ItemInfo>
              </Item>
            ))
          ) : (
            <p>Nenhuma loja encontrada.</p>
          )}
        </RestaurantList>
      )}
    </Container>
  );
}

export default Lojas;
