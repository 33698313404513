import React from 'react';
import generateStorytellingPDF from './generateStorytellingPDF';

const buttonStyle = {
  backgroundColor: '#00A4E3',
  color: 'white',
  border: 'none',
  borderRadius: '4px',
  padding: '10px 20px',
  fontSize: '12px',
  cursor: 'pointer',
  transition: 'background-color 0.3s ease',
};

const buttonHoverStyle = {
  backgroundColor: '#007bb5',
};

const buttonActiveStyle = {
  backgroundColor: '#005a87',
};

const PrintQRCodeButton = ({ qrCodeUrl, petId, className }) => {

  const handlePrintQR = () => {
    console.log('Botão "QR com Instruções" clicado');
    console.log('QR Code URL:', qrCodeUrl);
    console.log('Pet ID:', petId);

    if (!qrCodeUrl || !petId) {
      console.error('Dados do QR Code ou Pet ID não estão disponíveis');
      return;
    }

    // Chama a função de geração do PDF com o qrCodeUrl e petId
    generateStorytellingPDF(qrCodeUrl, petId);
  };

  return (
    <button
      style={buttonStyle}
      className={className} // Herda a className passada como propriedade
      onMouseOver={(e) => e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor}
      onMouseOut={(e) => e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor}
      onMouseDown={(e) => e.currentTarget.style.backgroundColor = buttonActiveStyle.backgroundColor}
      onMouseUp={(e) => e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor}
      onClick={handlePrintQR} // Chama a função ao clicar
    >
      QR com Instruções
    </button>
  );
};

export default PrintQRCodeButton;
