import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Overview from './Componets/Overview';
import Products from './Componets/Products';
import Orders from './Componets/Orders';
import Inventory from './Componets/Inventory';
import Settings from './Componets/Settings';
import AddProduct from './Componets/AddProduct';

const DashboardContent = () => {
    return (
        <div className="dashboard-content">
            <Routes>
            <Route path="/" element={<Navigate to="/painelUsuario/products" replace />} />
                <Route path="/overview" element={<Overview />} />
                <Route path="/products" element={<Products />} />
                <Route path="/orders" element={<Orders />} />
                <Route path="/inventory" element={<Inventory />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/Add" element={<AddProduct />} />
            </Routes>
        </div>
    );
};

export default DashboardContent;
