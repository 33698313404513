import React from 'react';

const Settings = () => {
    return (
        <div>
            <h2>Settings</h2>
            {/* Adicione aqui o conteúdo do resumo */}
        </div>
    );
};

export default Settings;
