import React, { useEffect } from 'react';
import styled from 'styled-components';
import { IoClose } from 'react-icons/io5';

export function MenuMobile({ menuIsVisible, setMenuIsVisible }) {
  // Remove a lógica de abrir o menu automaticamente

  const handleGestaoPetsClick = (event) => {
    event.preventDefault();
    alert("Faça login através da página Principal e tenha a Carterinha Digital de Saúde do seu Pet e muito mais!! Vem com a Gente, é de grátis!");
  };

  return (
    <Container isVisible={menuIsVisible}>
      <IoClose size={45} onClick={() => setMenuIsVisible(false)} />
      <nav>
        <a href="/home">Página Inicial</a>
        <a href="/dashboard" onClick={handleGestaoPetsClick}>Carteirinha Digital de Sáude</a>
        <a href="/Telemedicine">Teleconsulta Vet</a>
        <a href="/Marketplace">Marketplace</a>
        <a href="/RescueDashboard">Resgate</a>
        <a href="/SobreNos">Sobre Nós</a>
      </nav>
    </Container>
  );
};

const Container = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  transition: opacity 0.5s ease, transform 0.5s ease;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: ${({ isVisible }) => (isVisible ? 'translateY(0)' : 'translateY(100%)')};

  > svg {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
    color: #fff;
    transition: color 0.3s ease;
    &:hover {
      color: #f39c12;
    }
  }

  nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    color: #fff;
    font-size: 1.5rem;
    font-weight: bold;
    transition: opacity 0.5s ease, transform 0.5s ease;
    transform: ${({ isVisible }) => (isVisible ? 'scale(1)' : 'scale(0.8)')};
  }

  a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
    &:hover {
      color: #f39c12;
    }
  }
`;

export default MenuMobile;
