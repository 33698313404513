import React, { useState } from 'react';
import axios from 'axios';
import './PetForm.css';
import styled from 'styled-components';

const PetForm = ({ userId, updatePetList, closeForm }) => {
  const [petName, setPetName] = useState('');
  const [breed, setBreed] = useState('');
  const [age, setAge] = useState('');
  const [petType, setPetType] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Verificação dos campos obrigatórios
      if (!petName || !breed || !age || !petType) {
        console.error('Erro: informações de pet incompletas');
        return;
      }

      // Preparação dos dados do pet
      const petData = {
        userId,
        petName,
        breed,
        age,
        type: petType,
      };

      // Exibir os dados no console
      console.log('Dados enviados para o cadastro:', petData);

      // Enviando os dados do pet
      const response = await axios.post('https://vidapet.tech/CRUD-pets.php', petData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      console.log('Resposta do servidor ao cadastrar o pet:', response.data);

      if (response.data.includes('sucesso')) {
        setShowAlert(true);
        updatePetList();
        setTimeout(() => {
          closeForm();
        }, 2000);
      } else {
        console.error('Erro ao adicionar o pet:', response.data);
      }
    } catch (error) {
      console.error('Erro ao adicionar o pet:', error);
    }
  };

  return (
    <ModalBackground>
      <ModalContent>
        <button className="close-button" onClick={closeForm}>&times;</button>
        <h2>Adicionar Novo Pet</h2>
        {showAlert && <div className="alert">Pet Cadastrado com Sucesso!</div>}
        
        <form className="pet-form-container" onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Nome do Pet:</label>
            <input
              type="text"
              value={petName}
              onChange={(e) => setPetName(e.target.value)}
              placeholder="Digite o nome do pet"
            />
          </div>
          <div className="form-group">
            <label>Raça:</label>
            <input
              type="text"
              value={breed}
              onChange={(e) => setBreed(e.target.value)}
              placeholder="Digite a raça do pet"
            />
          </div>
          <div className="form-group">
            <label>Idade:</label>
            <input
              type="number"
              value={age}
              onChange={(e) => setAge(e.target.value)}
              placeholder="Digite a idade do pet"
            />
          </div>
          <div className="form-group">
            <label>Tipo:</label>
            <select value={petType} onChange={(e) => setPetType(e.target.value)}>
              <option value="">Selecione o tipo</option>
              <option value="Cão">Cão</option>
              <option value="Gato">Gato</option>
              <option value="Ave">Ave</option>
              <option value="Reptil">Reptil</option>
              <option value="Peixe">Peixe</option>
              <option value="Outro">Outro</option>
            </select>
          </div>
          <button type="submit" className="submit-button">Salvar</button>
        </form>
      </ModalContent>
    </ModalBackground>
  );
};

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
`;

export default PetForm;
