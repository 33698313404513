import React from 'react';
import { IoMdCloseCircle } from 'react-icons/io';
import './VaccineModal.css';
import HealthEventForm from '../Components/HealthEventForm'; // Certifique-se de que o caminho está correto

const VaccineModal = ({ vaccine, onClose, petId, onSave }) => {

  const handleSave = (vaccine, date, file) => {
    // Salva a vacina e dispara o onSave
    onSave(vaccine, date, file);
    onClose(); // Fecha o modal após salvar
  };

  return (
    <div className="vaccine-modal">
      <div className="modal-header">
        <h3>{vaccine.name}</h3>
        <IoMdCloseCircle className="close-button" onClick={onClose} />
      </div>
      <div className="modal-body">
        {/* Usando o formulário de eventos de saúde para registrar a vacina */}
        <HealthEventForm petId={petId} reloadPets={onSave} />
      </div>
    </div>
  );
};

export default VaccineModal;
