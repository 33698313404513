import React, { useState } from 'react';

function AdicionarLoja() {
    const [nome, setNome] = useState('');
    const [descricao, setDescricao] = useState('');
    const [foto, setFoto] = useState(null); // Atualize para aceitar um arquivo
    const [endereco, setEndereco] = useState('');
    const [telefone, setTelefone] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Cria um FormData para enviar arquivos
        const formData = new FormData();
        formData.append('name_loja', nome);
        formData.append('description', descricao);
        formData.append('foto', foto); // Adiciona o arquivo foto
        formData.append('endereco', endereco);
        formData.append('telefone', telefone);

        try {
            const response = await fetch('https://vidapet.tech/MARKET/API/adicionar_loja.php', {
                method: 'POST',
                body: formData, // Envia o FormData
            });

            const responseText = await response.text();
            console.log('Resposta do servidor:', responseText);

            // Tenta converter para JSON
            const result = JSON.parse(responseText);
            if (result.success) {
                alert('Loja adicionada com sucesso!');
                setNome('');
                setDescricao('');
                setFoto(null); // Limpa o arquivo
                setEndereco('');
                setTelefone('');
            } else {
                alert('Erro ao adicionar loja.');
            }
        } catch (error) {
            console.error('Erro ao enviar dados:', error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <label>Nome da Loja:</label>
                <input
                    type="text"
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                    required
                />
            </div>
            <div>
                <label>Descrição:</label>
                <textarea
                    value={descricao}
                    onChange={(e) => setDescricao(e.target.value)}
                    required
                ></textarea>
            </div>
            <div>
                <label>Foto:</label>
                <input
                    type="file"
                    onChange={(e) => setFoto(e.target.files[0])} // Atualiza com o arquivo selecionado
                    required
                />
            </div>
            <div>
                <label>Endereço:</label>
                <input
                    type="text"
                    value={endereco}
                    onChange={(e) => setEndereco(e.target.value)}
                    required
                />
            </div>
            <div>
                <label>Telefone:</label>
                <input
                    type="text"
                    value={telefone}
                    onChange={(e) => setTelefone(e.target.value)}
                    required
                />
            </div>
            <button type="submit">Adicionar Loja</button>
        </form>
    );
}

export default AdicionarLoja;
