import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PetList from './Components/PetList';
import { FaHome, FaPaw, FaVideo, FaLifeRing } from 'react-icons/fa';
import PetForm from './Components/PetForm';
import VetCall from './Components/VetCalls/VetCall';
import Carteirinha from './PetCard/Carteirinha';
import Resgate from '../Resgate/RescueDashboard';
import UserChat from './Components/Chat/UserChat';
import DashboardHome from './DashboardHome'; 
import { getUserIdFromFirebaseId } from '../Login/userService';
import axios from 'axios';
import './Dashboard.css';

const Dashboard = () => {
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState('');
  const [userPhoto, setUserPhoto] = useState('');
  const [reloadPets, setReloadPets] = useState(false);
  const [showPetForm, setShowPetForm] = useState(false);
  const [activeTab, setActiveTab] = useState('home');
  const [selectedPet, setSelectedPet] = useState(null);

  const navigate = useNavigate();

  // Verifica a autenticação do usuário no localStorage
  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const firebaseId = localStorage.getItem('firebaseId');
        if (firebaseId) {
          const userId = await getUserIdFromFirebaseId(firebaseId);
          if (userId) {
            setUserId(userId); // Define o userId se o usuário estiver logado
          } else {
            console.error('Falha ao obter userId do firebaseId');
          }
        } else {
          console.error('firebaseId não encontrado no localStorage');
        }
      } catch (error) {
        console.error('Erro ao verificar a autenticação:', error);
      }
    };

    checkAuthentication();
  }, []);

  // Busca os detalhes do usuário logado
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        if (userId) {
          const response = await axios.get(`https://vidapet.tech/sync-user.php/${userId}`);
          setUserName(response.data.name);
          setUserPhoto(response.data.photoURL);
        }
      } catch (error) {
        console.error('Erro ao obter detalhes do usuário:', error);
      }
    };

    fetchUserDetails();
  }, [userId]);

  const updatePetList = () => {
    setReloadPets(!reloadPets);
  };

  const handleAddPetClick = () => {
    setShowPetForm(true);
  };

  const handleCloseForm = () => {
    setShowPetForm(false);
  };

  // Atualizando o handleTabChange para garantir que o alert funcione corretamente
  const handleTabChange = async (tab) => {
    if (tab === 'pets') {
      const firebaseId = localStorage.getItem('firebaseId');
      if (!firebaseId) {
        alert('Para acessar a área de Pets, é necessário estar logado.');
        setActiveTab('home'); // Muda a aba ativa para "Início" após o alerta
      } else {
        if (!userId) {
          try {
            const fetchedUserId = await getUserIdFromFirebaseId(firebaseId);
            if (fetchedUserId) {
              setUserId(fetchedUserId); // Atualiza o userId se obtido corretamente
              setActiveTab(tab); // Agora permite a navegação para a aba de "Pets"
            } else {
              alert('Erro ao carregar informações do usuário. Por favor, tente novamente.');
              setActiveTab('home');
            }
          } catch (error) {
            console.error('Erro ao buscar o userId:', error);
            setActiveTab('home');
          }
        } else {
          setActiveTab(tab); // Permite a navegação para a aba de "Pets" se já estiver autenticado
        }
      }
    } else {
      setActiveTab(tab); // Permite a navegação para outras abas
    }
  };
  
  const handleShowPetCard = (pet) => {
    setSelectedPet(pet);
  };

  const handleClosePetCard = () => {
    setSelectedPet(null);
  };

  return (
    <div className="dashboard-container">
      <aside className="dashboard-sidebar">
        <h1 className="dashboard-title">Painel de Gestão de Pets</h1>
        <div className="tabs">
          <button onClick={() => handleTabChange('home')} className={activeTab === 'home' ? 'active' : ''}>
            <FaHome />
            Início
          </button>
          <button onClick={() => handleTabChange('pets')} className={activeTab === 'pets' ? 'active' : ''}>
            <FaPaw />
            Pets
          </button>
          <button onClick={() => handleTabChange('teleconsulta')} className={activeTab === 'teleconsulta' ? 'active' : ''}>
            <FaVideo />
            TeleConsulta Vet
          </button>
          <button onClick={() => handleTabChange('resgate')} className={activeTab === 'resgate' ? 'active' : ''}>
            <FaLifeRing />
            Resgate
          </button>
        </div>
      </aside>
      <main className="dashboard-content">
        {activeTab === 'home' && <DashboardHome />}
        {activeTab === 'pets' && userId ? (
          <div className="pet-cards-container">
            <PetList userId={userId} reloadPets={reloadPets} onShowPetCard={handleShowPetCard} />
          </div>
        ) : activeTab === 'pets' && !userId && (
          <p>Você precisa estar logado para visualizar os pets.</p> // Tratamento de erro se não estiver logado
        )}
        {activeTab === 'teleconsulta' && <VetCall userId={userId} />}
        {activeTab === 'resgate' && <Resgate />}
        {selectedPet && (
          <div className="full-screen-card">
            <Carteirinha pet={selectedPet} onClose={handleClosePetCard} userId={userId} />
          </div>
        )}
      </main>
      <UserChat userId={userId} />
    </div>
  );
  
};

export default Dashboard;
