import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Importa o hook para navegação
import axios from 'axios';
import '../Painel.css'; // Estilos para o gerenciamento de produtos

const Products = () => {
    const [products, setProducts] = useState([]);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null); // Para mensagens de sucesso
    const navigate = useNavigate(); // Hook para navegação

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const lojaId = localStorage.getItem('loja_id');
                if (!lojaId) {
                    throw new Error('ID da loja não encontrado no localStorage');
                }

                console.log(`Fetching products for loja_id: ${lojaId}`);

                const response = await axios.get(`https://vidapet.tech/MARKET/API/get_products.php?loja_id=${lojaId}`);
                
                console.log('Response received:', response);

                if (response.data.success) {
                    setProducts(response.data.products);
                } else {
                    throw new Error(response.data.error || 'Erro desconhecido da API');
                }
            } catch (error) {
                console.error('Erro ao buscar produtos:', error);
                setError(error.message || 'Erro ao buscar produtos.');
            }
        };

        fetchProducts();
    }, []);

    const handleDelete = async (productId) => {
        try {
            const response = await axios.post('https://vidapet.tech/MARKET/API/delete_product.php', { id: productId });
            
            if (response.data.success) {
                setSuccess('Produto excluído com sucesso!');
                setProducts(products.filter(product => product.id !== productId)); // Remove o produto da lista
            } else {
                setError(response.data.error || 'Erro ao excluir produto.');
            }
        } catch (error) {
            console.error('Erro ao excluir produto:', error);
            setError('Erro ao excluir produto.');
        }
    };

    return (
        <div className="product-management">
            <h1>Gerenciamento de Produtos</h1>
            {error && <p className="error-message">{error}</p>}
            {success && <p className="success-message">{success}</p>}
            <button className="add-product-button" onClick={() => navigate('/painelUsuario/Add')}>
                Adicionar Novo Produto
            </button>
            <div className="product-list">
                {products.length === 0 ? (
                    <p>Nenhum produto encontrado.</p>
                ) : (
                    products.map(product => (
                        <div key={product.id} className="product-item">
                            <img src={`https://vidapet.tech/MARKET/Produtos/${product.image}`} alt={product.name} className="product-image" />
                            <div className="product-info">
                                <h3>{product.name}</h3>
                                <p>{product.description}</p>
                                <p>R${product.price}</p>
                                <p>Estoque: {product.stock}</p>
                                <button 
                                    className="delete-button" 
                                    onClick={() => handleDelete(product.id)}
                                >
                                    Excluir
                                </button>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default Products;
