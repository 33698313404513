import React from 'react';

const Overview = () => {
    return (
        <div>
            <h2>Resumo da Loja</h2>
            {/* Adicione aqui o conteúdo do resumo */}
        </div>
    );
};

export default Overview;
