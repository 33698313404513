import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import './RescueDetails.css';
import pinIcon from '../../../imagens/icon-Map.png';

import { Helmet } from 'react-helmet';

// Definir o ícone para o mapa
const icon = L.icon({
    iconUrl: pinIcon,
    iconSize: [38, 38],
    iconAnchor: [19, 38]
});

const RescueDetails = () => {
  const { id } = useParams(); // Pega o ID do resgate da URL
  const [rescue, setRescue] = useState(null);

  useEffect(() => {
    // Fazer uma requisição para buscar os detalhes do resgate específico
    fetch(`https://vidapet.tech/api/resgate/rescues.php?id=${id}`)  // Ajustar URL da API
      .then((response) => response.json())
      .then((data) => setRescue(data))
      .catch((error) => console.error('Erro ao buscar os dados do resgate:', error));
  }, [id]);

  if (!rescue) {
    return <div>Carregando...</div>;
  }

  const shareOnWhatsApp = () => {
    const imageUrl = `https://vidapet.tech/${rescue.image_path}`;
    const locationUrl = `https://www.google.com/maps/place/${rescue.latitude},${rescue.longitude}`;
    const url = window.location.href;
    const message = `Olá! Encontrei um pet perdido que precisa de ajuda.
    \n\nMais informações sobre o resgate: ${url}\n\n
    Se puder ajudar ou conhecer alguém que possa, entre em contato. Para mais informações, visite nosso site em: https://vidapet.tech. Obrigado!`;
    window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`, '_blank');
  };

  const handleShareOnFacebook = () => {
    const url = window.location.href; // URL da página atual
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`, '_blank');
  };

  return (
    <div className="container1">

    <Helmet>

    <meta property="og:title" content={`Detalhes do Resgate de ${rescue.pet_type}`} />
        <meta property="og:description" content={`Encontrei um pet perdido que precisa de ajuda. Veja mais detalhes e ajude se puder!`} />
        <meta property="og:image" content={`https://vidapet.tech/${rescue.image_path}`} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />

    </Helmet>

      <div className="header1">
        <h1>Detalhes do Resgate</h1>
      </div>
      <div className="content-wrapper">
        <div className="image-container">
          <img src={`https://vidapet.tech/${rescue.image_path}`} alt={`Resgate ${rescue.type}`} />
        </div>
        <div className="details-map">
          <div className="map-container">
            <MapContainer center={[rescue.latitude, rescue.longitude]} zoom={13} style={{ height: '300px', width: '100%' }}>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker position={[rescue.latitude, rescue.longitude]} icon={icon}>
                <Popup>Localização do resgate</Popup>
              </Marker>
            </MapContainer>
          </div>

          <div className="details">
            <h2>Tipo: {rescue.pet_type}</h2>
            <p>Comentários: {rescue.comments}</p>
            <p>Localização: {rescue.latitude}, {rescue.longitude}</p>
            {/* Outras informações relevantes */}
          </div>  

          <div className="share-button">
            <button className="buttonWha" onClick={shareOnWhatsApp}>Compartilhar no WhatsApp</button>
            <button className="buttonFace" onClick={handleShareOnFacebook}>Compartilhar no Facebook</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RescueDetails;
