// src/components/Wallet/VidaPetCoin/VidaPetCoin.js
import React from 'react';

const VidaPetCoin = ({ balance }) => {
  return (
    <div>
      <h3>Seu saldo de PetCoin</h3>
      <p>{balance} PetCoins</p>
    </div>
  );
};

export default VidaPetCoin;
