import React, { useEffect, useRef } from 'react';
import axios from 'axios';

const JitsiMeeting = ({ roomName, user }) => {
  const jitsiContainerRef = useRef(null);

  useEffect(() => {
    const fetchTokenAndInitialize = async () => {
      try {
        const response = await axios.post('https://vidapet.tech/api/calls/generate-jwt.php', user);
        const { token } = response.data;

        if (jitsiContainerRef.current) {
          const domain = '8x8.vc';
          const options = {
            roomName: `vpaas-magic-cookie-fee39c2baf79451b9e8c3c3860389870/${roomName}`,
            width: '100%',
            height: '100%',
            parentNode: jitsiContainerRef.current,
            configOverwrite: {
              prejoinPageEnabled: false
            },
            interfaceConfigOverwrite: {
              HIDDEN_DOMAIN_INDICATOR: false
            },
            jwt: token
          };
          const api = new window.JitsiMeetExternalAPI(domain, options);
          return () => api.dispose();
        }
      } catch (error) {
        console.error('Failed to fetch JWT', error);
      }
    };

    fetchTokenAndInitialize();
  }, [roomName, user]);

  return <div ref={jitsiContainerRef} style={{ width: '100%', height: '600px' }}></div>;
};

export default JitsiMeeting;
