import Webcam from "react-webcam";
import styled from 'styled-components';
import { MdOutlineCamera } from "react-icons/md";
import { RiCloseCircleFill } from "react-icons/ri";


export const WebcamModal = styled.div`
  display: ${props => (props.isOpen ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  justify-content: center;
  align-items: center;
  z-index: 2000;
`;

export const WebcamStyled = styled(Webcam)`
  width: 90%;
  height: 72%;
  object-fit: cover;
`;

export const ButtonsContainer = styled.div`
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  padding: 10px;
  z-index: 10;
`;

export const PreviewContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  opacity: ${props => (props.show ? '1' : '0')};
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
  transition: opacity 0.3s ease-in-out;

  img {
    max-width: 90%;
    max-height: 80vh;
    border: 3px solid white;
    margin-bottom: 20px;
  }

  button {
    height: 45px;
    margin: 8px;
    background: #fff;
    border: none;
    outline: none;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    cursor: pointer;
    font-size: 16px;
    color: #333;
    font-weight: 700;
    font-size: 20px;

    &:active {
      transform: scale(0.9);
    }
  }
`;

export const CloseButton = styled(RiCloseCircleFill)`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 200;
  cursor: pointer;
  color: red;
`;

export const ButtonCamera = styled(MdOutlineCamera)`
  z-index: 200;
  cursor: pointer;
  color: black;
  font-size: 50px;
`;

export const ButtonRes = styled.button`
  height: 45px;
  background: #fff;
  border: none;
  outline: none;
  border-radius: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 18px;
  color: #333;
  font-weight: 700;
  margin: 0 1rem 0 2rem;

  &:hover{  
    background: #00A4E3;
    color: #fff;
  }

  &:active {
    transform: scale(0.9);
  }
`;

export const ButtonMap = styled.button`
margin: 1rem;
  height: auto;
  background: #d7d7d7;
  border: none;
  outline: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 18px;
  color: #333;
  font-weight: 700;

  &:hover{  
    background: #fff;
    color: black;
  }

  &:active {
    transform: scale(0.9);
  }
`;