import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './estilo.css'; // Certifique-se de importar o CSS

function CadastroUsuario() {
    const location = useLocation();
    const navigate = useNavigate();
    const { email } = location.state || {};
    const [username, setUsername] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setSenha] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch('https://vidapet.tech/MARKET/API/adicionar_users.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username, email, password, firstName, lastName })
            });
            const data = await response.json();

            if (data.success) {
                navigate('/criarloja', { state: { email, nome: `${firstName} ${lastName}` } });
            } else {
                alert(`Erro ao criar usuário: ${data.error}`); // Corrigido aqui
            }
        } catch (error) {
            console.error('Erro ao criar usuário:', error);
            alert('Erro ao criar usuário. Verifique o console para mais detalhes.');
        }
    };

    return (
        <div className="container">
            <div className="form-column">
                <h2>Cadastro - Etapa 2</h2>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label>Email:</label>
                        <input
                            type="email"
                            value={email}
                            readOnly
                        />
                    </div>
                    <div>
                        <label>Nome de Usuário:</label>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div className="name-container">
                        <div className="name-field">
                            <label>Primeiro Nome:</label>
                            <input
                                type="text"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                placeholder="Primeiro Nome"
                                required
                            />
                        </div>
                        <div className="name-field">
                            <label>Último Nome:</label>
                            <input
                                type="text"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                placeholder="Último Nome"
                                required
                            />
                        </div>
                    </div>
                    <div>
                        <label>Senha:</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setSenha(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit">Próxima Etapa</button>
                </form>
            </div>
        </div>
    );
}

export default CadastroUsuario;
