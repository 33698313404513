import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { IoMdCloseCircle } from 'react-icons/io';
import { FaSun, FaTint, FaImage, FaPalette, FaCut } from 'react-icons/fa';
import PrintQRCodeButton from './PrintQRCodeButton';
import HealthEventsView from '../Components/HealthEventsView';
import HealthEventForm from '../Components/HealthEventForm';
import petImagePlaceholder from '../../../imagens/sobrenos01.jpeg'; // Alterado para a nova imagem
import logo from '../../../imagens/MascoteLiliVidaPetTech.png';
import './CarteirinhaCustomStyle.css';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

const CarteirinhaExemplo = ({ pet, onClose, isInQrCodePage }) => {
  const [nomeTutor, setNomeTutor] = useState('');
  const [telefoneTutor, setTelefoneTutor] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('#ffffff');
  const [petPhotoBgColor, setPetPhotoBgColor] = useState('#f0f0f0');
  const [showHealthEvents, setShowHealthEvents] = useState(false);
  const [showHealthEventForm, setShowHealthEventForm] = useState(false);
  const [filter, setFilter] = useState('none');
  const [loading, setLoading] = useState(false);

  // Verificação de segurança para evitar acessar propriedades de pet que possam ser nulas
  const petImage = pet?.pet_3x4
    ? `https://vidapet.tech/vidapet/pets/${pet.pet_3x4.substring(pet.pet_3x4.lastIndexOf('/') + 1)}`
    : petImagePlaceholder; // Usa a imagem sobranos01.jpeg como padrão

  const CarteirinhaExemploRef = useRef();

  const alterarCorCarteirinhaExemplo = (cor) => {
    setBackgroundColor(`linear-gradient(135deg, ${cor} 30%, #ffffff 100%)`);
  };

  const alterarCorFotoPet = (cor) => {
    setPetPhotoBgColor(cor);
  };

  const handleSaveTutorInfo = async () => {
    try {
      const response = await fetch('https://vidapet.tech/api_carteira_pet.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          petId: pet.petId || pet.id,
          nomePet: pet.petName,
          tipo: pet.Type || "",
          raca: pet.breed || "",
          tutorUserId: pet.userId || "",
          observacao01: nomeTutor,
          observacao02: telefoneTutor,
          observacao03: ""
        }),
      });
  
      if (!response.ok) {
        const responseData = await response.json();
        throw new Error(`Erro da API: ${responseData.error}`);
      }
  
      alert('Informações salvas com sucesso!');
  
      const updatedResponse = await fetch(`https://vidapet.tech/api_carteira_pet.php?petId=${pet.petId || pet.id}`);
      if (!updatedResponse.ok) {
        throw new Error('Erro ao buscar os dados atualizados.');
      }
  
      const updatedData = await updatedResponse.json();
      setNomeTutor(updatedData.observacao01 || 'Tutor');
      setTelefoneTutor(updatedData.observacao02 || 'Telefone');
      setEditMode(false);
    } catch (error) {
      console.error('Erro ao salvar informações do tutor:', error);
    }
  };

  useEffect(() => {
    if (pet && pet.petId) {
      const fetchData = async () => {
        try {
          const response = await fetch(`https://vidapet.tech/api_carteira_pet.php?petId=${pet.petId || pet.id}`);
          if (!response.ok) throw new Error('Erro ao buscar dados da CarteirinhaExemplo');
          const responseData = await response.json();
          setNomeTutor(responseData.observacao01 || 'Tutor');
          setTelefoneTutor(responseData.observacao02 || 'Telefone');
        } catch (error) {
          console.error('Erro ao buscar dados:', error);
        }
      };
      fetchData();
    }
  }, [pet]);

  const qrCodeUrl = pet?.petId
    ? `https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(`https://vidapet.tech/resgate?qrcode=${pet.petId}`)}&size=100x100`
    : `https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(`https://vidapet.tech/resgate?qrcode=exemplo`)}&size=100x100`;

  const handlePrintPDF = async () => {
    if (CarteirinhaExemploRef.current) {
      const canvas = await html2canvas(CarteirinhaExemploRef.current);
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('portrait', 'mm', 'a4');
      pdf.addImage(imgData, 'PNG', 10, 10, 180, 160);
      pdf.save(`CarteirinhaExemplo_${pet.petName}.pdf`);
    }
  };

  return (
    <Container>
      {isInQrCodePage ? (
        <Content id="CarteirinhaExemplo-print" ref={CarteirinhaExemploRef} style={{ backgroundImage: backgroundColor }}>
          <Header>
            <Logo src={logo} alt="Vida Pet" />
            <Title>Carteira Digital Pet</Title>
          </Header>

          <ContainerCorPet>
            <CorCarteirinhaExemploContainer>
              <ColorButton className="color1" onClick={() => alterarCorCarteirinhaExemplo('#74b9ff')} />
              <ColorButton className="color2" onClick={() => alterarCorCarteirinhaExemplo('#ffeaa7')} />
              <ColorButton className="color3" onClick={() => alterarCorCarteirinhaExemplo('#81ecec')} />
              <ColorButton className="color4" onClick={() => alterarCorCarteirinhaExemplo('#fab1a0')} />
            </CorCarteirinhaExemploContainer>

            <PetPhotoContainer style={{ backgroundColor: petPhotoBgColor }}>
              <PetPhoto src={petImage} alt={pet?.petName || 'Pet Image'} style={{ filter }} />
            </PetPhotoContainer>

            <CorFotoPetContainer>
              <ColorButton className="color1" onClick={() => alterarCorFotoPet('#ffffff')} />
              <ColorButton className="color2" onClick={() => alterarCorFotoPet('#f0f0f0')} />
              <ColorButton className="color3" onClick={() => alterarCorFotoPet('#74b9ff')} />
              <ColorButton className="color4" onClick={() => alterarCorFotoPet('#ffeaa7')} />
            </CorFotoPetContainer>
          </ContainerCorPet>

          {loading && <LoadingMessage>Processando...</LoadingMessage>}

          <DataContainer>
            <InfoContainer>
              <InfoText><strong>Nome:</strong> {pet?.petName || 'Nome do Seu Pet'}</InfoText>
              <InfoText><strong>Idade:</strong> {pet?.age ? `${pet.age} anos` : 'Idade '}</InfoText>
              <InfoText><strong>Raça:</strong> {pet?.breed || 'Raça '}</InfoText>

              {editMode ? (
                <>
                  <InfoText>
                    <label>Nome do Tutor:</label>
                    <input type="text" value={nomeTutor} onChange={(e) => setNomeTutor(e.target.value)} />
                  </InfoText>
                  <InfoText>
                    <label>Telefone do Tutor:</label>
                    <input type="text" value={telefoneTutor} onChange={(e) => setTelefoneTutor(e.target.value)} />
                  </InfoText>
                  <Button onClick={handleSaveTutorInfo}>Salvar Informações do Tutor</Button>
                </>
              ) : (
                <>
                  <InfoText><strong>Tutor:</strong> {nomeTutor}</InfoText>
                  <InfoText><strong>Telefone:</strong> {telefoneTutor}</InfoText>
                  <Button onClick={() => setEditMode(true)}>Editar Informações do Tutor</Button>
                </>
              )}
            </InfoContainer>
            <QRCodeContainer>
              <QRCodeImage src={qrCodeUrl} alt="QR Code" />
            </QRCodeContainer>
          </DataContainer>

          <ButtonContainer>
            <PrintQRCodeButton qrCodeUrl={qrCodeUrl} />
            <Button onClick={handlePrintPDF}>CarteirinhaExemplo PDF</Button>
            <Button onClick={() => setShowHealthEvents(!showHealthEvents)}>Eventos</Button>
            <Button onClick={() => setShowHealthEventForm(!showHealthEventForm)}>Novo Evento</Button>
          </ButtonContainer>

          {showHealthEventForm && <HealthEventForm petId={pet?.petId} />}
        </Content>
      ) : (
        <ModalBackground>
          <ModalContent id="CarteirinhaExemplo-print" className="CarteirinhaExemplo-small" ref={CarteirinhaExemploRef} style={{ backgroundImage: backgroundColor }}>
            <Header>
              <Logo src={logo} alt="Vida Pet" />
              <Title>Carteira Digital Pet</Title>
              <CloseButton onClick={onClose} />
            </Header>
          </ModalContent>
        </ModalBackground>
      )}
    </Container>
  );
};

// Estilos para o modal, caso isInQrCodePage seja false
const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  border-radius: 16px;
  width: 280px;
  padding: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

// Estilos do container para a exibição no QR Code (sem modal)
const Container = styled.div`
  width: 100%;
  padding: 20px;
`;

const Content = styled.div`
  background-color: white;
  border-radius: 16px;
  padding: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

// Estilos do header
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px;
`;

const Logo = styled.img`
  width: 28px;
`;

const Title = styled.h2`
  flex: 1;
  font-size: 16px;
  text-align: center;
`;

const CloseButton = styled(IoMdCloseCircle)`
  font-size: 22px;
  cursor: pointer;
`;

// Estilos da foto do pet e das cores
const PetPhotoContainer = styled.div`
  width: 190px;
  height: 190px;
  padding: 8px;
  border: 4px solid #ffffff;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

const PetPhoto = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ContainerCorPet = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CorCarteirinhaExemploContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CorFotoPetContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ColorButton = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 5px 0;
  border: none;
  cursor: pointer;
`;

// Estilos da seção de informações
const DataContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 0;
`;

const InfoContainer = styled.div`
  width: 60%;
`;

const InfoText = styled.p`
  font-size: 14px;
  margin: 4px 0;
`;

const QRCodeContainer = styled.div`
  width: 40%;
  text-align: center;
`;

const QRCodeImage = styled.img`
  width: 80px;
`;

// Estilos dos botões
const ButtonContainer = styled.div`
  display: grid; /* Altere para grid */
  grid-template-columns: repeat(2, 1fr); /* Define 2 colunas com largura igual */
  gap: 8px; /* Espaçamento entre os botões */
  margin-top: 12px;
  justify-items: center; /* Centraliza os botões */
`;

const Button = styled.button`
  background-color: #81d3de;
  color: white;
  padding: 6px 12px;
  margin-top: 6px;
  border: none;
  border-radius: 6px;
  font-size: 12px;
  cursor: pointer;
  &:hover {
    background-color: #5fa6ab;
  }
`;

const FilterButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 8px;
`;

const FilterButton = styled.button`
  background-color: #f0f0f0;
  border: none;
  border-radius: 50%;
  padding: 4px;
  margin: 0 4px;
  cursor: pointer;
  font-size: 10px;
  &:hover {
    background-color: #ddd;
  }
`;

const LoadingMessage = styled.p`
  color: #666;
  font-size: 12px;
  margin-top: 8px;
`;

export {
  ModalBackground,
  ModalContent,
  Container,
  Content,
  Header,
  Logo,
  Title,
  CloseButton,
  PetPhotoContainer,
  PetPhoto,
  ContainerCorPet,
  CorCarteirinhaExemploContainer,
  CorFotoPetContainer,
  ColorButton,
  DataContainer,
  InfoContainer,
  InfoText,
  QRCodeContainer,
  QRCodeImage,
  ButtonContainer,
  Button,
  FilterButtons,
  FilterButton,
  LoadingMessage
};

export default CarteirinhaExemplo;
