import React from 'react';
import styled from 'styled-components';
import { FaFacebook, FaInstagram, FaWhatsapp } from "react-icons/fa";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterColumns>
        <FooterColumn>
          <h4>Navegação</h4>
          <ul>
            <li><a href="/Home">Página inicial</a></li>
            <li><a href="/Dashboard">Carteirinha Digital</a></li>
          </ul>
        </FooterColumn>
        <FooterColumn>
          <h4>Resgates</h4>
          <ul>
            <li><a href="/resgate">Pets Encontrados</a></li>
            <li><a href="/resgate">Pets na Rua</a></li>
          </ul>
        </FooterColumn>
        <FooterColumn>
          <h4>Sobre a Empresa</h4>
          <ul>
            <li><a href="/SobreNos">Nossa História</a></li>
            <li><a href="/SobreNos">Equipe</a></li>
            <li><a href="/SobreNos">Sobre Nós</a></li>
          </ul>
        </FooterColumn>
        <FooterColumn>
          <h4>Contato</h4>
          <p>Entre em contato conosco pelo WhatsApp!</p>
          <ContactLink href="https://wa.me/5521982992635" target="_blank" rel="noopener noreferrer">
            <FaWhatsapp /> 
          </ContactLink>
        </FooterColumn>
      </FooterColumns>
      <FooterBottom>
        <SocialLinks>
          <a href="https://www.facebook.com/profile.php?id=100095684404338&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
            <FaFacebook />
          </a>
          <a href="https://www.instagram.com/vidapet.tech?igsh=MTlkbGE3amc5cmp0Yw==" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </a>
          <a href="https://wa.me/5521982992635" target="_blank" rel="noopener noreferrer">
            <FaWhatsapp />
          </a>
        </SocialLinks>
        <Address>
          <p>&copy; 2023 Todos direitos reservados <a href="https://vidapet.tech">Vida Pet Tech</a>.</p>
        </Address>
      </FooterBottom>
    </FooterContainer>
  );
};

const FooterContainer = styled.footer`
  background-color: #4c8ea8;  /* Fundo azul */
  color: #ffffff;  /* Texto branco */
  padding: 40px 20px;
  text-align: center;
  margin-top: 20px;
`;

const FooterColumns = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const FooterColumn = styled.div`
  flex: 1;
  min-width: 200px;
  margin: 10px;

  h4 {
    margin-bottom: 10px;
    color: #ffffff; /* Texto branco */
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 10px;

      a {
        color: #ffffff;  /* Texto branco */
        text-decoration: none;
        transition: color 0.3s ease;

        &:hover {
          color: #FF7F50;  /* Laranja ao passar o mouse */
        }
      }
    }
  }

  p {
    color: #ffffff;  /* Texto branco */
    margin-bottom: 10px;
  }
`;

const ContactLink = styled.a`
  color: #ffffff; /* Texto branco */
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1.2rem;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: #FF7F50;  /* Laranja ao passar o mouse */
  }

  svg {
    font-size: 1.5rem;
  }
`;

const FooterBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 10px;

  a {
    color: #ffffff;  /* Texto branco */
    font-size: 24px;
    transition: color 0.3s ease;

    &:hover {
      color: #FF7F50;  /* Laranja ao passar o mouse */
    }
  }
`;

const Address = styled.div`
  p {
    margin: 0;
    color: #ffffff;  /* Texto branco */
  }

  a {
    color: #ffffff;  /* Texto branco */
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: #FF7F50;  /* Laranja ao passar o mouse */
    }
  }
`;

export default Footer;
