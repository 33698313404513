import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './estilo.css'; // Certifique-se de importar o CSS

function CadastroEmail() {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        // Navegar para a página de cadastro do usuário com o email como estado
        navigate('/cadastroUsuario', { state: { email } });
    };

    return (
        <div className="container">
            <div className="form-column">
                <h2>Cadastro - Etapa 1</h2>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label>Email:</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit">Próxima Etapa</button>
                </form>
            </div>
        </div>
    );
}

export default CadastroEmail;
