import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';

const Filter = ({ rescueData, onFilterChange }) => {
  const [pet_type, setPetType] = useState('');
  const [distance, setDistance] = useState(10); // Valor inicial do slider
  const [timeFrame, setTimeFrame] = useState('');

  const applyFilter = async () => {
    let filteredData = [...rescueData];

    // Log para verificar dados recebidos
    console.log('Dados de resgate:', rescueData);

    // Aplicar filtro de tipo de pet
    if (pet_type) {
      filteredData = filteredData.filter(rescue => {
        console.log(`Filtrando por tipo de pet: ${pet_type}`);
        return rescue.pet_type === pet_type;
      });
    }

    // Aplicar filtro de distância (usando a localização do usuário)
    if (distance) {
      const userLocation = await getUserLocation();
      if (userLocation) {
        const { latitude, longitude } = userLocation;
        filteredData = filteredData.filter(rescue => {
          const rescueDistance = calculateDistance(rescue.latitude, rescue.longitude, latitude, longitude);
          console.log(`Distância do resgate: ${rescueDistance} km`);
          return rescueDistance <= distance;
        });
      }
    }

    // Aplicar filtro de data
if (timeFrame) {
  const currentDate = moment();
  filteredData = filteredData.filter(rescue => {
    const rescueDate = moment(rescue.capture_datetime); // Usar capture_datetime
    const diffHours = currentDate.diff(rescueDate, 'hours');
    console.log(`Diferença de horas para o resgate: ${diffHours}`);
    if (timeFrame === '12h' && diffHours <= 12) return true;
    if (timeFrame === '7d' && diffHours <= 7 * 24) return true;
    if (timeFrame === '30d' && diffHours <= 30 * 24) return true;
    return false;
  });
}
    console.log('Dados filtrados:', filteredData);
    onFilterChange(filteredData);
  };

  const getUserLocation = async () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        position => {
          resolve({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          });
        },
        error => {
          console.error('Erro ao obter localização do usuário:', error);
          resolve(null);
        }
      );
    });
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Raio da Terra em km
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distância em km
    return distance;
  };

  const deg2rad = (deg) => {
    return deg * (Math.PI / 180);
  };

  const clearFilter = () => {
    setPetType('');
    setDistance(10);
    setTimeFrame('');
    onFilterChange(rescueData); // Chama a função passada por propriedade com os dados originais
  };

  return (
    <FilterContainer>
      <SelectContainer>
        <label htmlFor="pet_type">Tipo de Pet:</label>
        <select id="pet_type" value={pet_type} onChange={(e) => setPetType(e.target.value)}>
          <option value="">Selecione um tipo de pet</option>
          <option value="cachorro">Cachorro</option>
          <option value="gato">Gato</option>
          <option value="outros">Outros</option>
        </select>
      </SelectContainer>
      <SelectContainer>
        <label htmlFor="distance">Distância (km): {distance} km</label>
        <input
          type="range"
          id="distance"
          min="0"
          max="100"
          value={distance}
          onChange={(e) => setDistance(e.target.value)}
        />
      </SelectContainer>
      <SelectContainer>
        <label htmlFor="timeFrame">Data:</label>
        <select id="timeFrame" value={timeFrame} onChange={(e) => setTimeFrame(e.target.value)}>
          <option value="">Selecione um período</option>
          <option value="12h">Últimas 12 horas</option>
          <option value="7d">Últimos 7 dias</option>
          <option value="30d">Últimos 30 dias</option>
        </select>
      </SelectContainer>

      <div>
        <ApplyButton onClick={applyFilter}>Aplicar Filtro</ApplyButton>
        <ClearButton onClick={clearFilter}>Limpar Filtro</ClearButton>
      </div>
    </FilterContainer>
  );
};

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 20px;

  @media (min-width: 800px) {
    flex-direction: initial;
    display: inline-flex;
  }
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  label {
    font-weight: bold;
  }

  select,
  input {
    padding: 8px;
    border: 2px solid #ccc;
    border-radius: 49px;
    width: 202px;
    background: azure;
  }
`;

const ApplyButton = styled.button`
  padding: 8px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #0056b3;
  }
`;

const ClearButton = styled.button`
  padding: 8px 15px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-left: 10px;

  &:hover {
    background-color: #c82333;
  }
`;

export default Filter;
