import React from 'react';
import './MarketPlace.css'; // Importe o arquivo CSS para estilização
import PaginaEmConstrucao from "../../components/PaginaEmConstrucao"

function MarketPlace() {
  return (
    <div >
     <PaginaEmConstrucao></PaginaEmConstrucao>
    </div>
  );
}

export default MarketPlace;
