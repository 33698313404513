// src/components/Wallet/Wallet.js
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import VidaPetCoin from './VidaPetCoin/VidaPetCoin'; // Importando VidaPetCoin
import GamificationPoints from './GamificationPoints'; // Importando GamificationPoints
import { useNavigate } from 'react-router-dom'; // Importando useNavigate para navegação

const Wallet = () => {
  const [walletBalance, setWalletBalance] = useState(0); // Definindo o estado para o saldo da Wallet
  const [camificationPoints, setGamificationPoints] = useState(0); // Definindo o estado para os pontos de gamificação
  const [isWalletVisible, setIsWalletVisible] = useState(false); // Estado para controlar a visibilidade do menu
  const [enableMenu, setEnableMenu] = useState(false); // Novo estado para controlar se o menu pode ser aberto ou não

  const navigate = useNavigate(); // Hook para navegação

  useEffect(() => {
    if (isWalletVisible && enableMenu) {
      fetchWalletBalance();
      fetchGamificationPoints();
    }
  }, [isWalletVisible, enableMenu]);

  const fetchWalletBalance = async () => {
    try {
      const response = await fetch('http://vidapet.tech/api/DB/vidapettech/zwallet/get_balance.php'); // Caminho ajustado
      const data = await response.json();
      setWalletBalance(data.balance);
    } catch (error) {
      console.error('Erro ao buscar o saldo da Wallet:', error);
    }
  };

  const fetchGamificationPoints = async () => {
    try {
      const response = await fetch('http://vidapet.tech/api/DB/vidapettech/zwallet/get_camification_points.php'); // Caminho ajustado
      const data = await response.json();
      setGamificationPoints(data.points);
    } catch (error) {
      console.error('Erro ao buscar os pontos de gamificação:', error);
    }
  };

  const toggleWallet = () => {
    if (enableMenu) {
      setIsWalletVisible(!isWalletVisible);
    }
  };

  const handleNavigation = (path) => {
    navigate(path); // Função para navegação
  };

  return (
    <WalletContainer>
      <WalletHeader onClick={toggleWallet}>
        <h4>Wallet</h4>
      </WalletHeader>
      {isWalletVisible && enableMenu && (
        <WalletDetails>
          <VidaPetCoin balance={walletBalance} /> {/* Passando o saldo para o VidaPetCoin */}
          <p>Saldo da PetCoin: ${walletBalance}</p>
          <GamificationPoints points={camificationPoints} /> {/* Passando os pontos de gamificação */}
          
          <MenuLinks>
            <MenuItem onClick={() => handleNavigation('/dashboard')}>Painel de Usuário</MenuItem>
            <MenuItem onClick={() => handleNavigation('/telemedicine')}>TeleConsulta Vet</MenuItem>
            <MenuItem onClick={() => handleNavigation('/rescue-dashboard')}>Resgate</MenuItem>
            <MenuItem onClick={() => handleNavigation('/marketplace')}>Marketplace</MenuItem>
          </MenuLinks>
        </WalletDetails>
      )}
    </WalletContainer>
  );
};

export default Wallet;

const WalletContainer = styled.div`
  margin-top: 10px;
  padding: 10px;
  background-color: #444;
  border-radius: 4px;
  color: #ffffff;
  font-size: 14px;
`;

const WalletHeader = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
  }
`;

const WalletDetails = styled.div`
  margin-top: 10px;
  p {
    margin: 0;
  }
`;

const MenuLinks = styled.div`
  margin-top: 10px;
`;

const MenuItem = styled.div`
  cursor: pointer;
  padding: 8px 0;
  font-size: 14px;
  &:hover {
    background: #555;
  }
`;
