import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ProductDetails from './ProductDetails';
import ImageCarousel from './ImageCarousel';
import RelatedProducts from './RelatedProducts';
import ProductDetailsTabs from './ProductDetailsTabs';
import './ProductDetail.css';

const ProductDetail = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [selectedImage, setSelectedImage] = useState('');

  useEffect(() => {
    if (!productId) return;

    const fetchProduct = async () => {
      try {
        const response = await fetch(`https://vidapet.tech/MARKET/API/detalhe_produto.php?id=${productId}`);
        if (!response.ok) throw new Error('Erro na requisição');
        const data = await response.json();
        if (data.success) {
          setProduct(data.product);
          setRelatedProducts(data.relatedProducts);
          setSelectedImage(data.product.image || '');
          console.log('Produto:', data.product); // Adicione este log
        } else {
          console.error('Mensagem da API:', data.message || 'Erro na API');
        }
      } catch (error) {
        console.error('Erro ao buscar dados do produto:', error);
      }
    };

    fetchProduct();
  }, [productId]);

  if (!product) return <p>Carregando...</p>;

  return (
    <div className="product-detail">
      <nav className="breadcrumb">
        <a href="/MarketPlace">MarketPlace</a> / <a href={`/lojas/${product.lojas_id}`}>{product.loja_name}</a> / {product.name}
      </nav>

      <div className="product-content">
        <ImageCarousel product={product} selectedImage={selectedImage} setSelectedImage={setSelectedImage} />
        <ProductDetails product={product} />
      </div>

      <ProductDetailsTabs 
        description={product.description} 
        technicalInfo={product.technicalInfo}
        packagingContent={product.packagingContent}
      />

      <RelatedProducts relatedProducts={relatedProducts} />
    </div>
  );
};

export default ProductDetail;
