import React, { useState, useEffect } from 'react';
import axios from 'axios';
import VetList from './VetList';
import JitsiMeeting from './JitsiMeeting';
import CallPayments from './CallPayments';
import './VetCall.css';

const VetCall = () => {
  const [roomName, setRoomName] = useState('');
  const [error, setError] = useState('');
  const [isListVisible, setIsListVisible] = useState(true);
  const [deviceStatus, setDeviceStatus] = useState('');
  const [userId, setUserId] = useState(null);
  const [paymentPending, setPaymentPending] = useState(false);
  const [paymentSuccessful, setPaymentSuccessful] = useState(false);

  useEffect(() => {
    const loggedUserId = sessionStorage.getItem('userId');
    setUserId(loggedUserId);

    const checkDeviceStatus = async () => {
      try {
        const deviceResponse = await axios.get('https://vidapet.tech/api/calls/get_device_status.php');
        setDeviceStatus(deviceResponse.data.status);
      } catch (error) {
        console.error('Erro ao verificar status do dispositivo:', error);
        setError('Erro ao verificar status do dispositivo.');
      }
    };

    checkDeviceStatus();
  }, []);

  const handleSelectRoom = (roomName) => {
    setRoomName(roomName);
    setIsListVisible(false);
  };

  const handleStartCall = () => {
    if (paymentSuccessful) {
      setRoomName(roomName);
    } else {
      setPaymentPending(true);
    }
  };

  const handlePaymentSuccess = () => {
    setPaymentSuccessful(true);
    setPaymentPending(false);
    setRoomName(roomName);
  };

  const handlePaymentFailure = () => {
    setPaymentSuccessful(false);
    setPaymentPending(false);
    alert('Falha no pagamento. Tente novamente.');
  };

  const handleToggleListVisibility = () => {
    setIsListVisible(!isListVisible);
  };

  return (
    <div className="vet-call-container">
      <button onClick={handleToggleListVisibility} className="toggle-list-btn">
        {isListVisible ? 'Minimizar Lista' : 'Mostrar Lista'}
      </button>
      {deviceStatus === 'Off' ? (
        <div className="error-message">Telemedicina não habilitada para este usuário</div>
      ) : (
        <>
          {isListVisible && (
            <>
              <p>Encontre uma sala de Atendimento disponível na lista abaixo:</p>
              <VetList onSelectRoom={handleSelectRoom} />
            </>
          )}
        </>
      )}
      {roomName && !paymentPending && (
        <>
          <p>Você selecionou a sala: {roomName}</p>
          <p>Clique em "Iniciar Chamada Veterinária" para entrar na sala de Atendimento.</p>
          <button onClick={handleStartCall} className="start-call-btn">Iniciar Chamada Veterinária</button>
        </>
      )}
      {roomName && paymentPending && (
        <CallPayments
          userId={userId}
          roomName={roomName}
          onPaymentSuccess={handlePaymentSuccess}
          onPaymentFailure={handlePaymentFailure}
        />
      )}
      {roomName && paymentSuccessful && <JitsiMeeting roomName={roomName} />}
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default VetCall;
