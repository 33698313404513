import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import HealthEventsView from './../Components/HealthEventsView';
import HealthEventForm from '../../Dashboard/Components/HealthEventForm'; // Importa o formulário de eventos

const HealthEventsModal = ({ onClose, petId, onAddEvent, onEditEvent }) => {
  const [isAddingEvent, setIsAddingEvent] = useState(false); // Estado para alternar entre a lista e o formulário
  const [events, setEvents] = useState([]); // Armazena os eventos de saúde

  // Carrega os eventos ao montar o componente
  useEffect(() => {
    const fetchHealthEvents = async () => {
      try {
        const response = await fetch(`https://vidapet.tech/get_health_events.php?petId=${petId}`);
        const data = await response.json();
        setEvents(data); // Atualiza os eventos com os dados recebidos
      } catch (error) {
        console.error('Erro ao carregar eventos de saúde:', error);
      }
    };

    if (petId) {
      fetchHealthEvents(); // Chama a função de busca dos eventos
    }
  }, [petId]); // Executa a busca sempre que o `petId` mudar

  const handleAddEventClick = () => {
    setIsAddingEvent(true); // Exibe o formulário quando o botão é clicado
  };

  const handleSaveEvent = (event) => {
    // Lógica para salvar o evento e voltar para a lista
    setIsAddingEvent(false); // Retorna para a visualização da lista após salvar
    if (typeof onAddEvent === 'function') {
      onAddEvent(event);
    }
  };

  return (
    <ModalWrapper>
      <ModalHeader>
        <ModalTitle>Eventos de Saúde</ModalTitle>
        <CloseButton onClick={onClose}>X</CloseButton>
      </ModalHeader>

      <ModalBody>
        <ScrollableContent>
          {isAddingEvent ? (
            <HealthEventForm petId={petId} onSaveEvent={handleSaveEvent} reloadPets={() => setIsAddingEvent(false)} />
          ) : (
            <HealthEventsView petId={petId} events={events} onEditEvent={onEditEvent} />
          )}
        </ScrollableContent>
      </ModalBody>

      <ModalFooter>
        {!isAddingEvent && (
          <AddEventButton onClick={handleAddEventClick}>Adicionar Evento de Saúde</AddEventButton>
        )}
      </ModalFooter>
    </ModalWrapper>
  );
};

// Estilos para o modal
const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  width: 90%;
  max-width: 600px;
  max-height: 80vh; /* Limita a altura do modal */
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalTitle = styled.h2`
  font-size: 18px;
  color: #333;
`;

const CloseButton = styled.button`
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #ff3333;
  }
`;

const ModalBody = styled.div`
  margin: 15px 0;
`;

const ScrollableContent = styled.div`
  max-height: 50vh; /* Limite de altura para o conteúdo rolável */
  overflow-y: auto; /* Adiciona a barra de rolagem */
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: center;
`;

const AddEventButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    background-color: #0056b3;
  }
`;

export default HealthEventsModal;
