import styled from 'styled-components';
import { FaLock, FaEye, FaEyeSlash } from "react-icons/fa";

export const PasswordFaLock = styled(FaLock)`
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  cursor: pointer;
`;

export const PasswordFaEyeSlash = styled(FaEyeSlash)`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  cursor: pointer;
`;

export const PasswordFaEye = styled(FaEye)`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  cursor: pointer;
`;

const ErrorBox = styled.div`
  color: red;
  margin-top: 10px;
  font-size: 20px;
`;
const SucessoBox = styled.div`
  color: green;
  margin-top: 10px;
  font-size: 20px;
`;

export const Cont = styled.div`
  padding: 10px;
  position: fixed;
  top: 2rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 151;
  justify-content: center;
  align-items: center;
  border-radius: 30px;

  
  .close {
    color: white;
    margin-left: 20rem;

    &:hover {
      color: red;
    }
    &:active {
      transform: scale(0.9);
    }
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .wrapper {
    width: 420px;
    background: transparent;
    color: #fff;
    border-radius: 10px;
    padding: 30px 40px;

    h1 {
      font-size: 30px;
      text-align: center;
    }
  }
    .wrapperRegistro {
    width: 420px;
    background: black;
    color: #fff;
    border-radius: 10px;
    padding: 30px 40px;

    h1 {
      font-size: 30px;
      text-align: center;
    }
  }

  .message{
  margin-top: 23px;
  background-color: dimgray;
  padding: 14px;

  }

  .confirm{
  display: grid;
  text-align: initial;
  color: darkgray;

  p {
  padding:10px;
  margin-bottom: 12px;
  }

  }

  .input-box {
    position: relative;
    width: 100%;
    height: 50px;
    margin: 30px 0;

    input {
      width: 100%;
      height: 100%;
      background: transparent;
      outline: none;
      border: 2px solid rgba(255, 255, 255, .2);
      border-radius: 40px;
      font-size: 16px;
      color: #fff;
      padding: 20px 45px 20px 20px;
    }

    ::placeholder {
      color: #fff;
    }

    .icon {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 16px;
    }

    .icon1 {
      position: absolute;
      right: 50px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 16px;
    }
  }

  .remember-forgot {
    display: flex;
    justify-content: end;
    font-size: 14.5px;  
  }

  .buttonRe{
    background: border-box;
    color:aqua;

    &:hover {
      text-decoration: underline;
    }

     &:active {
      transform: scale(0.9);
    }
    
  }

  .buttonEntrar {
    width: 100%;
    height: 45px;
    background: #fff;
    border: none;
    outline: none;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    cursor: pointer;
    font-size: 16px;
    color: #333;
    font-weight: 700;
    font-size: 20px;

    &:active {
      transform: scale(0.9);
    }
  }

  .register-link {
    font-size: 14.5px;
    text-align: center;
    margin: 20px 0 15px;

    p a {
      color: #fff;
      text-decoration: none;
      font-weight: 600;
    }

    p a:hover {
      text-decoration: underline;
    }
  }

  .logo {
    margin: 10px;
    font-size: 50px;

    &:hover {
      color: #fff;
    }

    &:active {
      transform: scale(0.8);
    }
  }

  @media (max-width: 900px) {
    left: 50%;
    transform: translateX(-50%);
    .wrapper {
      width: 420px;
    }

    .close {
      color: white;
      margin-left: 19rem;
    }
  }

  @media (max-width: 750px) {
    left: 50%;
    transform: translateX(-50%);

    .wrapper {
      width: 350px;
    }

    .close {
      color: white;
      margin-left: 17rem;
    }
  }

  @media (max-width: 500px) {
    left: 50%;
    transform: translateX(-50%);

    .wrapper {
      width: 350px;
    }

    .close {
      color: white;
      margin-left: 17rem;
    }
  }

  @media (max-width: 400px) {
    left: 50%;
    transform: translateX(-50%);

    .wrapper {
      width: 350px;
    }

    .close {
      color: white;
      margin-left: 17rem;
    }
  }
`;
