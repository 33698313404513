import React, { useState, useEffect } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import MarketplaceLogin from '../Login/Usuario/Login-MK'; // Certifique-se de que o caminho está correto

const Header = () => {
    const [user, setUser] = useState(null);
    const [isLoginVisible, setIsLoginVisible] = useState(false);

    useEffect(() => {
        // Recupera o usuário do localStorage
        const storedUser = JSON.parse(localStorage.getItem('user'));
        if (storedUser) {
            setUser(storedUser);
        }
    }, []);

    const handleLoginSuccess = (userData) => {
        setUser(userData);
        setIsLoginVisible(false); // Fecha o modal de login após sucesso
    };

    const handleLogout = () => {
        // Limpa o localStorage e sessionStorage
        localStorage.removeItem('user');
        localStorage.removeItem('loja_id'); // Remove o loja_id, se aplicável
        sessionStorage.clear(); // Opcional: Limpa o sessionStorage, se estiver usando
        setUser(null);
    };

    return (
        <header style={{ padding: '10px', backgroundColor: '#4c8ea8', color: 'white' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <h1>Marketplace</h1>
                </div>
                <div>
                    {user ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <FaUserCircle size={40} />
                            <span style={{ marginLeft: '10px' }}>{user.email}</span>
                            <button onClick={handleLogout} style={{ marginLeft: '10px' }}>
                                Sair
                            </button>
                        </div>
                    ) : (
                        <button onClick={() => setIsLoginVisible(true)}>
                            Login
                        </button>
                    )}
                </div>
            </div>
            {isLoginVisible && (
                <MarketplaceLogin onLoginSuccess={handleLoginSuccess} />
            )}
        </header>
    );
};

export default Header;
