import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const HealthEventForm = ({ petId, reloadPets }) => {
  const [eventType, setEventType] = useState('Vacina');
  const [eventDescription, setEventDescription] = useState('');
  const [userDate, setUserDate] = useState('');
  const [attachment, setAttachment] = useState(null);

  const handleAddEvent = async () => {
    try {
      const formData = new FormData();
      formData.append('eventType', eventType);
      formData.append('eventDescription', eventDescription);
      formData.append('petId', petId);
      formData.append('userDate', userDate);

      if (attachment !== null) {
        formData.append('attachment', attachment);
      }

      await axios.post('https://vidapet.tech/health-events.php', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Limpar campos após adicionar evento
      setEventType('Vacina');
      setEventDescription('');
      setUserDate('');
      setAttachment(null);

      if (typeof reloadPets === 'function') {
        reloadPets();
      }
    } catch (error) {
      console.error('Erro ao adicionar evento de saúde:', error);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setAttachment(file);
  };

  return (
    <FormContainer>
      <h4>Adicionar Evento de Saúde</h4>

      <FormGroup>
        <Label htmlFor="eventType">Tipo de Evento:</Label>
        <Select
          id="eventType"
          value={eventType}
          onChange={(e) => setEventType(e.target.value)}
        >
          <option value="Consulta Vet Presencial">Consulta Vet Presencial</option>
          <option value="Teleconsulta Vet">Teleconsulta Vet</option>
          <option value="Medicação">Medicação</option>
          <option value="Exame">Exame</option>
          <option value="Vacina">Vacina</option>
          <option value="Outros">Outros</option>
        </Select>
      </FormGroup>

      <FormGroup>
        <Label htmlFor="eventDescription">Descrição:</Label>
        <TextArea
          id="eventDescription"
          value={eventDescription}
          onChange={(e) => setEventDescription(e.target.value)}
        ></TextArea>
      </FormGroup>

      <FormGroup>
        <Label htmlFor="userDate">Data do Evento:</Label>
        <Input
          type="date"
          id="userDate"
          value={userDate}
          onChange={(e) => setUserDate(e.target.value)}
        />
      </FormGroup>

      <FormGroup>
        <Label htmlFor="attachment">Anexo:</Label>
        <Input
          type="file"
          id="attachment"
          accept="image/*, application/pdf"
          onChange={handleFileChange}
        />
      </FormGroup>

      <Button onClick={handleAddEvent}>Adicionar Evento</Button>
    </FormContainer>
  );
};

export default HealthEventForm;

// Estilos com styled-components
const FormContainer = styled.div`
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
  background-color: #fff;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
  background-color: #fff;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  background-color: #fff;
  resize: vertical;
`;

const Button = styled.button`
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;
