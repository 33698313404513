import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CallPayments.css'; // Importa o arquivo CSS

const CallPayments = ({ onPaymentSuccess }) => {
    const [clientName, setClientName] = useState('');
    const [clientCpf, setClientCpf] = useState('');
    const [clientEmail, setClientEmail] = useState('');
    const [coupon, setCoupon] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [statusMessage, setStatusMessage] = useState('');
    const [oBigFator, setOBigFator] = useState(null);
    const [oBigFatorQR, setOBigFatorQR] = useState(null);
    const [error, setError] = useState(null);
    const [paymentStatus, setPaymentStatus] = useState(null);
    const [paymentCheckAttempts, setPaymentCheckAttempts] = useState(0);
    const [timeLeft, setTimeLeft] = useState(600); // Contador de 10 minutos (600 segundos)

    const isValidCpf = (cpf) => cpf.length === 11;
    const isValidName = (name) => name.trim().length > 0;
    const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email);

    const checkClientInDatabase = async (cpf) => {
        try {
            setStatusMessage('Verificando cliente no banco de dados...');
            const response = await axios.get(`https://vidapet.tech/api/pgs/asaas/clients_added_db.php?cpf=${cpf}`);
            return response.data;
        } catch (error) {
            console.error('Erro ao verificar cliente no banco de dados:', error);
            setError('Erro ao verificar cliente no banco de dados.');
            throw error;
        }
    };

    const createClientInAsaas = async (name, cpf, email) => {
        try {
            setStatusMessage('Criando cliente no Asaas...');
            const response = await axios.post('https://vidapet.tech/api/pgs/asaas/create_client.php', { nome_completo: name, cpf, email });
            return response.data;
        } catch (error) {
            console.error('Erro ao criar cliente no Asaas:', error);
            setError('Erro ao criar cliente no Asaas.');
            throw error;
        }
    };

    const getClientIdAfterDelay = async (cpf) => {
        try {
            setStatusMessage('Aguardando confirmação do cliente...');
            let retries = 10;
            while (retries > 0) {
                await new Promise(resolve => setTimeout(resolve, 2000));

                const client = await checkClientInDatabase(cpf);
                if (client && client.client_id) {
                    return client;
                }

                retries--;
            }

            console.error('ID do cliente não disponível após várias tentativas.');
            setError('ID do cliente não disponível após várias tentativas.');
            return null;
        } catch (error) {
            console.error('Erro ao buscar cliente após atraso:', error);
            setError('Erro ao buscar cliente após atraso.');
            throw error;
        }
    };

    const createBillingInAsaas = async (customerId) => {
        try {
            setStatusMessage('Criando cobrança no Asaas...');
            const response = await axios.post('https://vidapet.tech/api/pgs/asaas/create_billing.php', { customer: customerId });
            return response.data;
        } catch (error) {
            console.error('Erro ao criar cobrança no Asaas:', error.response ? error.response.data : error.message);
            setError('Erro ao criar cobrança no Asaas.');
            throw error;
        }
    };

    const registerBillingInBackend = async (billingData) => {
        try {
            setStatusMessage('Registrando cobrança no backend...');
            const response = await axios.post('https://vidapet.tech/api/pgs/asaas/create_billing_db.php', billingData);
            return response.data;
        } catch (error) {
            console.error('Erro ao registrar cobrança no backend:', error);
            setError('Erro ao registrar cobrança no backend.');
            throw error;
        }
    };

    const getPixQrCode = async (billingId) => {
        try {
            setStatusMessage('Obtendo QR Code Pix...');
            const response = await axios.get(`https://vidapet.tech/api/pgs/asaas/get_pix_qr.php?billing_id=${billingId}`);
            console.log('Resposta da API do QR Code Pix:', response.data);

            const [firstJson, secondJson] = response.data.split('}{').map((item, index) => {
                return (index === 0 ? item + '}' : '{' + item).replace(/}{/, '},{');
            });

            const qrCodeData = JSON.parse(firstJson);
            return qrCodeData;
        } catch (error) {
            console.error('Erro ao obter QR Code Pix:', error);
            setError('Erro ao obter QR Code Pix.');
            throw error;
        }
    };

    const checkPaymentStatus = async (billingId) => {
        try {
            setStatusMessage('Verificando status do pagamento...');
            console.log('Verificando status do pagamento para billingId:', billingId);
            const response = await axios.get(`https://vidapet.tech/api/pgs/asaas/check_payment_status.php?billing_id=${billingId}`);
            console.log('Resposta da verificação de pagamento:', response.data);
            return response.data;
        } catch (error) {
            console.error('Erro ao verificar status do pagamento:', error);
            setError('Erro ao verificar status do pagamento.');
            throw error;
        }
    };

    const monitorPayment = (billingId) => {
        console.log('Iniciando monitoramento do pagamento para billingId:', billingId);
        const interval = setInterval(async () => {
            try {
                const status = await checkPaymentStatus(billingId);
                setPaymentCheckAttempts(prev => prev + 1);

                console.log('Tentativa de verificação de pagamento:', paymentCheckAttempts, 'Status:', status);

                if (status && status.paymentConfirmed) {
                    clearInterval(interval);
                    console.log('Pagamento confirmado para billingId:', billingId);
                    setPaymentStatus('confirmed');
                    setStatusMessage('Pagamento confirmado! Redirecionando para telemedicina...');
                    onPaymentSuccess();
                }

                if (paymentCheckAttempts >= 20) {
                    clearInterval(interval);
                    console.log('Tempo limite para confirmação de pagamento excedido para billingId:', billingId);
                    setPaymentStatus('timeout');
                    setError('Tempo limite para confirmação de pagamento excedido.');
                }
            } catch (error) {
                clearInterval(interval);
                console.log('Erro ao verificar status do pagamento para billingId:', billingId);
                setError('Erro ao verificar status do pagamento.');
            }
        }, 10000); // Verificar a cada 10 segundos
    };

    useEffect(() => {
        let timer;
        if (timeLeft > 0 && paymentStatus !== 'confirmed') {
            timer = setInterval(() => {
                setTimeLeft((prevTime) => prevTime - 1);
            }, 1000);
        } else if (timeLeft <= 0) {
            setPaymentStatus('timeout');
            setError('Tempo limite para confirmação de pagamento excedido.');
        }
        return () => clearInterval(timer);
    }, [timeLeft, paymentStatus]);

    const createClientAndPayment = async () => {
        setIsProcessing(true);
        setError(null);

        if (!isValidCpf(clientCpf)) {
            setError('CPF inválido.');
            setIsProcessing(false);
            return;
        }

        if (!isValidName(clientName)) {
            setError('Nome completo inválido.');
            setIsProcessing(false);
            return;
        }

        if (!isValidEmail(clientEmail)) {
            setError('Email inválido.');
            setIsProcessing(false);
            return;
        }

        try {
            setStatusMessage('Verificando se o cliente já existe...');
            const existingClient = await checkClientInDatabase(clientCpf);

            let clientId;

            if (existingClient.status === 'success' && existingClient.client_id) {
                clientId = existingClient.client_id;
            } else {
                const clientResponse = await createClientInAsaas(clientName, clientCpf, clientEmail);

                if (clientResponse.status === 'success') {
                    const updatedClient = await getClientIdAfterDelay(clientCpf);

                    if (updatedClient.status === 'success' && updatedClient.client_id) {
                        clientId = updatedClient.client_id;
                    } else {
                        console.error('ID do cliente não disponível após criação.');
                        setError('ID do cliente não disponível.');
                        return;
                    }
                } else {
                    console.error('Falha ao criar cliente no Asaas:', clientResponse.message);
                    setError('Falha ao criar cliente no Asaas.');
                    return;
                }
            }

            const clientInDb = await checkClientInDatabase(clientCpf);
            if (clientInDb.status !== 'success' || !clientInDb.client_id) {
                console.error('Cliente não encontrado na tabela após criação.');
                setError('Cliente não encontrado na tabela após criação.');
                return;
            }

            const billingResponse = await createBillingInAsaas(clientId);

            if (billingResponse && billingResponse.id) {
                const billingData = {
                    client_id: clientId,
                    amount: billingResponse.value,
                    asaas_billing_id: billingResponse.id
                };

                const dbResponse = await registerBillingInBackend(billingData);

                if (dbResponse.status === 'pending') {
                    try {
                        const qrCodeResponse = await getPixQrCode(billingResponse.id);

                        if (qrCodeResponse.encodedImage && qrCodeResponse.payload) {
                            setOBigFator(qrCodeResponse.encodedImage);
                            setOBigFatorQR(qrCodeResponse.payload);
                            monitorPayment(billingResponse.id); // Iniciar monitoramento do pagamento
                            setStatusMessage('Pague a consulta no valor de R$90,00 via Pix QR ou Copia e Cola. Assim que realizar o pagamento, a sua sala de Telemedicina irá iniciar.');
                        } else {
                            console.error('Dados do QR Code Pix ausentes ou inválidos:', qrCodeResponse);
                            setError('Dados do QR Code Pix ausentes ou inválidos.');
                        }
                    } catch (qrError) {
                        console.error('Erro ao obter QR Code Pix:', qrError);
                        setError('Erro ao obter QR Code Pix.');
                    }
                } else {
                    console.error('Resposta inesperada do backend:', dbResponse);
                    setError('Resposta inesperada do backend.');
                }
            } else {
                console.error('Resposta inesperada da criação da cobrança:', billingResponse);
                setError('Resposta inesperada da criação da cobrança.');
            }
        } catch (error) {
            console.error('Erro geral ao processar pagamento:', error);
            setError('Erro ao processar pagamento.');
        } finally {
            setIsProcessing(false);
        }
    };

    const handleCouponSubmit = () => {
        if (coupon.trim().toUpperCase() === 'PAGO') {
            setPaymentStatus('confirmed');
            setStatusMessage('Pagamento confirmado! Redirecionando para telemedicina...');
            onPaymentSuccess();
        } else {
            setError('Cupom inválido.');
        }
    };

    return (
        <div className="call-payments-container">
            <h2>Gerar Pagamento</h2>
            {paymentStatus !== 'confirmed' && (
                <>
                    <p>Inserir seu nome, CPF e email para gerar o Pix da Teleconsulta.</p>
                    <input
                        type="text"
                        placeholder="Nome completo"
                        value={clientName}
                        onChange={(e) => setClientName(e.target.value)}
                    />
                    <input
                        type="text"
                        placeholder="CPF"
                        value={clientCpf}
                        onChange={(e) => setClientCpf(e.target.value)}
                    />
                    <input
                        type="email"
                        placeholder="Email"
                        value={clientEmail}
                        onChange={(e) => setClientEmail(e.target.value)}
                    />
                    <button onClick={createClientAndPayment} disabled={isProcessing}>
                        {isProcessing ? 'Processando...' : 'Gerar Pagamento'}
                    </button>
                    {statusMessage && <p className="status">{statusMessage}</p>}
                </>
            )}

            {oBigFator && (
                <>
                    <div className="qr-code-container">
                        <h3>QR Code Pix:</h3>
                        <img
                            src={`data:image/png;base64,${oBigFator}`}
                            alt="QR Code Pix"
                        />
                        <p>Copia e Cola: {oBigFatorQR}</p>
                        <p className="timer">Tempo restante para pagamento: {Math.floor(timeLeft / 60)}:{timeLeft % 60 < 10 ? `0${timeLeft % 60}` : timeLeft % 60} minutos</p>
                    </div>
                    <div className="coupon-container">
                        <input
                            type="text"
                            placeholder="Cupom"
                            value={coupon}
                            onChange={(e) => setCoupon(e.target.value)}
                        />
                        <button onClick={handleCouponSubmit}>Validar Cupom</button>
                    </div>
                </>
            )}
            {error && <p className="error">{error}</p>}
            {paymentStatus === 'confirmed' && (
                <div>
                    <h3>Pagamento confirmado!</h3>
                    <p>Seu pagamento foi confirmado. Agora você pode acessar a telemedicina.</p>
                </div>
            )}
            {paymentStatus === 'timeout' && (
                <div>
                    <h3>Tempo esgotado</h3>
                    <p>O tempo limite para confirmação de pagamento foi excedido. Por favor, tente novamente.</p>
                </div>
            )}
        </div>
    );
};

export default CallPayments;
