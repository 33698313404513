import styled from 'styled-components';

// Container principal
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

// Container do mapa
export const MapContainerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: ${(props) => (props.isExpanded ? '70vh' : '30vh')};
  margin: 20px 0;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: height 0.3s ease;
  
  @media (max-width: 768px) {
    height: ${(props) => (props.isExpanded ? '50vh' : '20vh')};
  }
`;

// Lista de resgates
export const RescueList = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

// Card de resgate
export const RescueCard = styled.article`
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-bottom: 20px;
`;

// Imagem do card de resgate
export const RescueImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  cursor: pointer; /* Adicionando cursor pointer para indicar que é clicável */
`;

// Conteúdo do card
export const CardContent = styled.div`
  padding: 15px;
`;

// Título do card
export const CardTitle = styled.h2`
  margin: 0;
  padding-bottom: 10px;
  color: #333;
  font-size: 1.5rem;
  border-bottom: 1px solid #ddd;
`;

// Comentários
export const Comments = styled.p`
  color: #555;
  font-size: 0.9rem;
  line-height: 1.4;
  margin: 10px 0;
`;

// Link de localização
export const ButtonRe = styled.button`
  height: auto;
  background: #fff;
  border: none;
  outline: none;
  border-radius: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 18px;
  color: #333;
  font-weight: 700;
  margin: 1rem;

  &:hover{  
    background: #00A4E3;
    color: #fff;
  }

  &:active {
    transform: scale(0.9);
  }
`;

// Wrapper para botões de ação
export const ActionButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

// Wrapper para botões de compartilhamento
export const ShareButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

// Botão de ação de pet
export const PetActionButton = styled.button`
  flex: 1;
  background-color: ${(props) => props.color || '#ffc107'};
  color: white;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    filter: brightness(90%);
  }

  &:focus {
    outline: none;
  }
`;

// Botão de compartilhar no Facebook
export const FacebookShareButton = styled(PetActionButton)`
  background-color: #3b5998;

  &:hover {
    background-color: #2d4373;
  }
`;

// Botão de compartilhar no WhatsApp
export const WhatsAppShareButton = styled(PetActionButton)`
  background-color: #25d366;

  &:hover {
    background-color: #1da851;
  }
`;

// Botão de seguir
export const FollowButton = styled(PetActionButton)`
   width: 100%;
  height: 45px;
  background: #fff;
  border: none;
  outline: none;
  border-radius: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 16px;
  color: #333;
  font-weight: 700;

  &:active {
    transform: scale(0.9);
  }
`;

// Botão de expandir/reduzir mapa
export const MapExpandButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
  background-color: #007bff;
  color: white;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
  width: auto;
  background-color: white;
  color: black;

  &:hover {
    background-color: #0056b3;
  }

  &:focus {
    outline: none;
  }
`;

// Overlay de status de resgate
export const RescueStatusOverlay = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-weight: bold;
`;

// Modal de imagem
export const ImageModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

// Imagem do modal
export const ModalImage = styled.img`
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
`;

// Estilos adicionais e correções
export const PopupContent = styled.div`
  width: 150px;
  height: 150px;

  img {
    height: 90px;
  }
`;


