import React from 'react';
import petListImg from '../../imagens/petlist.png'; // Substitua com o caminho correto da imagem
import carteirinhaImg from '../../imagens/carteirinha1.png'; // Substitua com o caminho correto da imagem
import vacinacoesImg from '../../imagens/vacinacoes1.png'; // Substitua com o caminho correto da imagem
import consultasImg from '../../imagens/consultas1.png'; // Substitua com o caminho correto da imagem
import examesImg from '../../imagens/exames1.png'; // Substitua com o caminho correto da imagem
import qrCodeImg from '../../imagens/qrcode1.png'; // Substitua com o caminho correto da imagem

const DashboardHome = () => {
  return (
    <div style={styles.dashboardHome}>
      <h2 style={styles.title}>Bem-vindo ao Painel de Gestão de Pets</h2>
      <p style={styles.paragraph}>
        Cadastre-se Grátis Gerênciar todas as informações de saúde dos seus pets de forma prática e organizada. Abaixo estão as funcionalidades que você pode acessar:
      </p>

      <div style={styles.infoSection}>
        <div style={styles.infoItem}>
          <img src={petListImg} alt="Lista de Pets" style={styles.infoImage} />
          <p style={styles.paragraph}><strong>Lista de Pets:</strong> Visualize e gerencie todos os seus pets cadastrados.</p>
        </div>
        <div style={styles.infoItem}>
          <img src={carteirinhaImg} alt="Carteirinha Digital" style={styles.infoImage} />
          <p style={styles.paragraph}><strong>Cadastro da Carteirinha Digital:</strong> Crie uma carteirinha digital com todas as informações essenciais do seu pet.</p>
        </div>
        <div style={styles.infoItem}>
          <img src={vacinacoesImg} alt="Vacinações" style={styles.infoImage} />
          <p style={styles.paragraph}><strong>Vacinações:</strong> Registre e acompanhe o histórico de vacinações dos seus pets.</p>
        </div>
        <div style={styles.infoItem}>
          <img src={consultasImg} alt="Consultas" style={styles.infoImage} />
          <p style={styles.paragraph}><strong>Consultas:</strong> Agende e registre consultas veterinárias.</p>
        </div>
        <div style={styles.infoItem}>
          <img src={examesImg} alt="Exames" style={styles.infoImage} />
          <p style={styles.paragraph}><strong>Exames:</strong> Acompanhe e registre todos os exames realizados pelos seus pets.</p>
        </div>
        <div style={styles.infoItem}>
          <img src={qrCodeImg} alt="QR Code" style={styles.infoImage} />
          <p style={styles.paragraph}><strong>Impressão:</strong> Imprima a carteirinha digital completa ou apenas o QR Code do seu pet para facilitar a identificação.</p>
        </div>
      </div>
    </div>
  );
};

const styles = {
  dashboardHome: {
    padding: '20px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
  },
  title: {
    color: '#333',
    textAlign: 'center',
    marginBottom: '20px',
  },
  paragraph: {
    color: '#555',
    fontSize: '16px',
    lineHeight: '1.6',
    marginBottom: '20px',
  },
  infoSection: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    marginTop: '20px',
  },
  infoItem: {
    flex: '1 1 30%',
    margin: '10px',
    textAlign: 'center',
  },
  infoImage: {
    maxWidth: '100%',
    height: 'auto',
    borderRadius: '8px',
    marginBottom: '10px',
  },
};

export default DashboardHome;
