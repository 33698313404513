import React, { useState } from 'react';
import styled from 'styled-components';
import { auth, provider } from '../../firebase'; // Importar provider para o login com Google
import { FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc'; // Ícone do Google
import { MdEmail } from "react-icons/md";
import axios from 'axios'; 
import { useNavigate } from 'react-router-dom';

const Login = ({ closeLogin, openRegistro, openEsqueciSenha }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [showLoginOptions, setShowLoginOptions] = useState(false); // Novo estado para mostrar botões "Login" e "Criar Conta"
  const [showLoginFields, setShowLoginFields] = useState(false); // Novo estado para exibir campos de login
  const navigate = useNavigate();

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Função de login com email e senha
  const handleLogin = async () => {
    setError('');

    try {
      const userCredential = await auth.signInWithEmailAndPassword(email, password);
      const user = userCredential.user;

      const profileInfo = {
        firebaseId: user.uid,
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL,
      };

      const backendResponse = await axios.post('https://vidapet.tech/sync-user.php', profileInfo);

      if (backendResponse.data.status === 'success') {
        navigate('/dashboard');
        closeLogin();
      } else {
        setError('Erro ao sincronizar com o backend: ' + backendResponse.data.message);
      }
    } catch (error) {
      setError('Erro no processo de login: ' + error.message);
    }
  };

  // Função de login com Google
  const handleGoogleLogin = () => {
    auth.signInWithPopup(provider)
      .then(async (result) => {
        const user = result.user;

        const profileInfo = {
          firebaseId: user.uid,
          email: user.email,
          displayName: user.displayName,
          photoURL: user.photoURL,
        };

        const backendResponse = await axios.post('https://vidapet.tech/sync-user.php', profileInfo);

        if (backendResponse.data.status === 'success') {
          navigate('/dashboard');
          closeLogin();
        } else {
          setError('Erro ao sincronizar com o backend: ' + backendResponse.data.message);
        }
      })
      .catch((error) => {
        setError('Erro no login com Google: ' + error.message);
      });
  };

  return (
    <Wrapper>
      <LoginSection>
        <GoogleLoginButton onClick={handleGoogleLogin}>
          <FcGoogle className='logo' />
          <p>Registre-se ou Faça Login com Google</p>
        </GoogleLoginButton>

        <Divider />

        {/* Botão para exibir as opções de login/cadastro */}
        <DirectLoginButton onClick={() => setShowLoginOptions(!showLoginOptions)}>
          Registre-se ou Faça Login com Email
        </DirectLoginButton>

        {showLoginOptions && ( // Mostra os botões Login e Criar Conta
          <>
            <LoginOptionButton onClick={() => setShowLoginFields(true)}>
              Login
            </LoginOptionButton>
            <LoginOptionButton onClick={openRegistro}>
              Criar Conta
            </LoginOptionButton>
          </>
        )}

        {showLoginFields && ( // Mostra os campos de email e senha apenas se showLoginFields for true
          <>
            <InputContainer>
              <MdEmail className='icon' />
              <input
                type='email'
                placeholder='Email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </InputContainer>

            <InputContainer>
              <FaLock className='icon' />
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder='Senha'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              {showPassword ? (
                <FaEyeSlash className='toggle-icon' onClick={handleTogglePasswordVisibility} />
              ) : (
                <FaEye className='toggle-icon' onClick={handleTogglePasswordVisibility} />
              )}
            </InputContainer>

            {error && <ErrorBox>{error}</ErrorBox>}

            <Button onClick={handleLogin}>Entrar</Button>
            <AuxiliaryLinks>
              <Link onClick={openEsqueciSenha}>Esqueci a Senha</Link>
            </AuxiliaryLinks>
          </>
        )}
      </LoginSection>
    </Wrapper>
  );
};

export default Login;

// Estilos
const Wrapper = styled.div`
  width: 100%;
  padding: 8px 10px;
  background: #333;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
`;

const LoginSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #555;
  margin: 8px 0;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  background: #444;
  border: 1px solid #555;
  border-radius: 5px;
  padding: 6px;

  input {
    flex: 1;
    border: none;
    background: none;
    padding-left: 8px;
    font-size: 12px;
    outline: none;
    color: #ffffff;
  }

  .icon {
    color: #888;
  }

  .toggle-icon {
    color: #888;
    cursor: pointer;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 8px;
  background: #FF7F50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;

  &:hover {
    background-color: #e6673b;
  }
`;

const GoogleLoginButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #db4437;

  .logo {
    margin-right: 5px;
    font-size: 14px;
  }

  p {
    margin: 0;
    font-size: 14px;
  }

  &:hover {
    background-color: #c5372c;
  }
`;

// Novo botão para exibir os campos de login direto
const DirectLoginButton = styled(Button)`
  background-color: #FF7F50;
  margin-top: 8px;

  &:hover {
    background-color: #e6673b;
  }
`;

// Botão para selecionar Login ou Criar Conta
const LoginOptionButton = styled(Button)`
  background-color: #4A90E2;
  margin-top: 8px;

  &:hover {
    background-color: #357ABD;
  }
`;

const AuxiliaryLinks = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
`;

const Link = styled.span`
  font-size: 12px;
  color: #FF7F50;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const ErrorBox = styled.div`
  color: red;
  margin-top: 8px;
  font-size: 12px;
  text-align: center;
`;
