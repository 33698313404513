import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const HealthEventsView = ({ petId, reloadEvents, viewOnly = false, gridView = false }) => {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [editingEvent, setEditingEvent] = useState(null);
  const [formData, setFormData] = useState({
    event_type: '',
    event_description: '',
    event_date: '',
    anexo_photo: null,
  });

  useEffect(() => {
    const fetchHealthEvents = async () => {
      try {
        const response = await axios.get(`https://vidapet.tech/get_health_events.php?petId=${petId}`);
        if (Array.isArray(response.data)) {
          setEvents(response.data);
        } else {
          setEvents([]);
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Erro ao carregar eventos de saúde:', error);
        setIsLoading(false);
      }
    };

    fetchHealthEvents();
  }, [petId, reloadEvents]);

  const handleEditEvent = (event) => {
    setEditingEvent(event);
    setFormData({
      event_type: event.event_type,
      event_description: event.event_description,
      event_date: event.event_date,
      anexo_photo: event.anexo_photo || null,
    });
  };

  const handleCancelEdit = () => {
    setEditingEvent(null);
    setFormData({
      event_type: '',
      event_description: '',
      event_date: '',
      anexo_photo: null,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, anexo_photo: file || null });
  };

  const handleSaveEvent = async () => {
    const { event_type, event_description, event_date, anexo_photo } = formData;
    const formattedDate = new Date(event_date).toISOString().split('T')[0];
    
    try {
      const updatedEvent = {
        ...editingEvent,
        event_type,
        event_description,
        event_date: formattedDate,
        anexo_photo: anexo_photo ? anexo_photo.name : null,
      };
  
      const response = await axios.put(`https://vidapet.tech/CRUD-Health-Events.php?eventId=${editingEvent.id}`, updatedEvent);
  
      reloadEvents(true);
      setEditingEvent(null);
      setFormData({
        event_type: '',
        event_description: '',
        event_date: '',
        anexo_photo: null,
      });
    } catch (error) {
      console.error('Erro ao salvar evento:', error);
    }
  };

  const handleDeleteHealthEvent = async (eventId) => {
    try {
      const confirmDelete = window.confirm('Você realmente deseja excluir este evento de saúde?');
      
      if (confirmDelete) {
        const response = await axios.delete(`https://vidapet.tech/CRUD-Health-Events.php?id=${eventId}`);
  
        if (response.status === 200 && response.data === 'Evento de saúde excluído com sucesso') {
          reloadEvents(true);
        } else {
          console.error('Falha ao excluir evento de saúde.');
        }
      }
    } catch (error) {
      console.error('Erro ao excluir evento de saúde:', error);
    }
  };

  const renderAttachmentThumbnail = (attachmentPath) => {
    if (!attachmentPath) return null;
    
    const extension = attachmentPath.split('.').pop().toLowerCase();
    
    if (['jpg', 'jpeg', 'png'].includes(extension)) {
      return <img src={`https://vidapet.tech/${attachmentPath}`} alt="Anexo" style={{ maxWidth: '100px' }} />;
    } else if (extension === 'pdf') {
      return <img src="pdf-icon.png" alt="PDF Icon" style={{ maxWidth: '100px' }} />;
    } else {
      return <span>Arquivo: {attachmentPath}</span>;
    }
  };

  const eventTypeOptions = ['Consulta', 'Vacinação', 'Exame', 'Cirurgia', 'Outro'];

  return (
    <Container gridView={gridView}>
      <h3>Eventos de Saúde</h3>
      {isLoading ? (
        <p>Carregando eventos...</p>
      ) : events && events.length > 0 ? (
        <EventsGrid>
          {events.map((event) => (
            <EventCard key={event.id}>
              {editingEvent === event ? (
                <>
                  <div>
                    <label>Tipo de Evento:</label>
                    <select
                      name="event_type"
                      value={formData.event_type}
                      onChange={handleInputChange}
                    >
                      <option value="">Selecione o tipo</option>
                      {eventTypeOptions.map((option) => (
                        <option key={option} value={option}>{option}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label>Descrição:</label>
                    <textarea
                      name="event_description"
                      value={formData.event_description}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <label>Data:</label>
                    <input
                      type="date"
                      name="event_date"
                      value={formData.event_date}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <label>Anexar Foto:</label>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                    {formData.anexo_photo && (
                      <button onClick={() => setFormData({ ...formData, anexo_photo: null })}>
                        Remover Anexo
                      </button>
                    )}
                  </div>
                  <button onClick={handleSaveEvent}>Salvar</button>
                  <button onClick={handleCancelEdit}>Cancelar</button>
                </>
              ) : (
                <>
                  <strong>Tipo:</strong> {event.event_type}<br />
                  <strong>Descrição:</strong> {event.event_description}<br />
                  <strong>Data:</strong> {event.event_date}<br />
                  {event.anexo_photo && (
                    <div>
                      <strong>Anexo:</strong>
                      {renderAttachmentThumbnail(event.anexo_photo)}
                    </div>
                  )}
                  {!viewOnly && (
                    <>
                      <button onClick={() => handleEditEvent(event)}>Editar</button>
                      <button onClick={() => handleDeleteHealthEvent(event.id)}>Excluir</button>
                    </>
                  )}
                </>
              )}
            </EventCard>
          ))}
        </EventsGrid>
      ) : (
        <p>Nenhum evento de saúde encontrado.</p>
      )}
    </Container>
  );
};

// Estilos do componente
const Container = styled.div`
  max-width: 100%;
`;

const EventsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 15px;
  width: 100%;

  @media(min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media(min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const EventCard = styled.div`
  background: #f9f9f9;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

  @media print {
    break-inside: avoid;
    page-break-inside: avoid;
  }
`;

export default HealthEventsView;
