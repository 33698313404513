import React from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import DashboardContent from './DashboardContent';
import './Painel.css';

const Dashboard = () => {

    return (
        <div className="dashboard">
            <Sidebar />
            <div className="main-content">
                <Header />
                <DashboardContent />
            </div>
        </div>
    );
};

export default Dashboard;
