import React from 'react';
import './Time.css'; // Certifique-se de que o caminho está correto

const TeamMemberCard = ({ name, role, photo, description, socialLinks }) => {
    return (
        <div className="team-member-card">
            <div className="avatar">
                <img src={photo} alt={name} />
            </div>
            <div className="info">
                <h3>{name}</h3>
                <p className="role">{role}</p>
                <p className="description">{description}</p>  {/* Renderiza a descrição correta aqui */}
                <div className="social-links">
                    {socialLinks.map((link, index) => (
                        <a key={index} href={link.url} target="_blank" rel="noopener noreferrer">
                            <img src={link.icon} alt={link.name} />
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TeamMemberCard;
