// src/pages/MarketPlace/components/Lojas/Products/ProductList.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../LojaDetalhes.css'; // Certifique-se de que o caminho para os estilos está correto

const ProductList = ({ products }) => {
  return (
    <div className="product-list">
      {products && products.length > 0 ? (
        products.map((product) => (
          <Link 
            to={`/produto/${product.id}`} 
            key={product.id} 
            className="product-item"
          >
            <img 
              src={`https://vidapet.tech/MARKET/Produtos/${product.image}`} 
              alt={product.name} 
              className="product-image" 
            />
            <div className="product-info">
              <h3 className="product-name">{product.name}</h3>
              <p className="product-price">R${product.price}</p>
            </div>
          </Link>
        ))
      ) : (
        <p className="no-products">Nenhum produto encontrado.</p>
      )}
    </div>
  );
};

export default ProductList;
