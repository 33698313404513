import React from 'react';

const Chatbot = async ({ userMessage, chatHistory }) => {
    try {
        const response = await fetch('https://api.cohere.ai/v1/chat', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer 7zGKPQrQ1i27aYcXfyLqd9PJmUjDAuHKCsfKa6kp`,  // Substitua pela sua chave API
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                message: `Por favor, responda de forma breve: ${userMessage}`,
                chat_history: chatHistory.map(msg => ({
                    text: msg.text,
                    role: msg.senderId === 'guest' ? 'User' : 'Chatbot',
                })),
                model: 'command-r',
                stream: false,
                temperature: 0.7,  // Mantém a resposta moderada
            }),
        });

        const data = await response.json();

        return {
            text: data.text || "Desculpe, não entendi sua pergunta.",
            senderId: '9999',  // Identificador do chatbot Lili
        };
    } catch (error) {
        console.error('Erro ao enviar mensagem para a Lili:', error);
        return {
            text: "Desculpe, algo deu errado. Tente novamente mais tarde.",
            senderId: '9999',  // Identificador do chatbot Lili
        };
    }
};

export default Chatbot;
