import React from 'react';
import { useNavigate } from 'react-router-dom';
import './estilo.css'; // Certifique-se de importar o CSS

function Confirmacao() {
    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate('/');
    };

    return (
        <div className="confirmation-container">
            <h2>Loja Criada com Sucesso!</h2>
            <p>A sua loja foi criada com sucesso. Agora você pode começar a gerenciar o seu negócio.</p>
            <button onClick={handleRedirect}>Voltar para a Página Inicial</button>
        </div>
    );
}

export default Confirmacao;
