import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import './App.css';

// Componentes básicos e páginas
import PageHeader from './components/PageHeaders';
import PageFooter from './components/Footer/Footer';
import Home from "./pages/Home/Home";
import SobreNos from './pages/SobreNos/IndexSobreNos';
import MenuMobile from './components/MenuMobile';  

// Login
import Login from "./pages/Login/Login";
import Registro from "./pages/Login/Registro";

// Carteirinha
import RescueDashboard from "./pages/Resgate/RescueDashboard";  
import RescueDetails from "./pages/Resgate/Components/RescueDetails";  
import Dashboard from './pages/Dashboard/Dashboard';
import Carteirinha from './pages/Dashboard/PetCard/Carteirinha';

// Telemedicina
import VetCall from './pages/Dashboard/Components/VetCalls/VetCall';
import VetDashboard from './pages/Dashboard/VetDash/VetDashboard';
import Telemedicine from './pages/Telemedicine/Telemedicine';
import QrCode from './pages/Resgate/QrCode/QrCode';

// MarketPlace
import MarketPlace from './pages/MarketPlace/MarketPlace';
import EsqueciSenha from './pages/Login/EsqueciSenha';
import ConfirmSenha from './pages/Login/ConfirmSenha';
import Item from './pages/MarketPlace/components/Lojas/Products/ProductDetail';
import Lojas from './pages/MarketPlace/components/Lojas/index';
import LojaDetalhes from './pages/MarketPlace/components/Pages/LojaDetalhes';
import AddLojas from './pages/MarketPlace/components/Pages/Adicionar/AddLojas';
import Parceiro from './pages/MarketPlace/Dashboard-MK/Login/Cadastro';
import Criarloja from './pages/MarketPlace/Dashboard-MK/Login/CriarLoja';
import Confirmacao from './pages/MarketPlace/Dashboard-MK/Login/Confirmacao';
import CadastroUsuario from './pages/MarketPlace/Dashboard-MK/Login/CadastroUsuario';
import PainelUsuario from './pages/MarketPlace/Dashboard-MK/Painel/Painel';
import LoginMK from './pages/MarketPlace/Dashboard-MK/Login/Usuario/Login-MK';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    font-family: 'Arial', sans-serif;
  }
`;

function App() {
  const [menuIsVisible, setMenuIsVisible] = useState(false);
  const [currentCall, setCurrentCall] = useState(null);

  const handleCallStart = (roomName) => {
    setCurrentCall(roomName);
  };

  const handleJoinCall = (roomName) => {
    setCurrentCall(roomName);
  };

  const handleCallClose = () => {
    setCurrentCall(null);
  };

  const handleSave = (data) => {
    console.log('Dados salvos:', data);
  };

  return (
    <>
      <GlobalStyle />
      <div className="App">
        <Router>
          <PageHeader setMenuIsVisible={setMenuIsVisible} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/index.html" element={<Home />} />

            <Route path="/resgate" element={<RescueDashboard />} />
            <Route path="/rescues/:id" element={<RescueDetails />} />

            <Route path="/SobreNos" element={<SobreNos />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/login" element={<Login />} />
            <Route path="/registro" element={<Registro />} />
            <Route path="/carteirinha" element={<Carteirinha />} />
            <Route path="/marketplace" element={<MarketPlace />} />
            <Route path="/Telemedicine" element={<Telemedicine />} />
            <Route path="/vet-dashboard" element={<VetDashboard onJoinCall={handleJoinCall} />} />        
            <Route path="/qr-code/:petId" element={<QrCode />} />

            {/* Rotas do MarketPlace */}
            <Route path="/AdicionarLojas" element={<AddLojas />} />
            <Route path="/Parceiro" element={<Parceiro />} />
            <Route path="/criarloja" element={<Criarloja />} />
            <Route path="/Confirmacao" element={<Confirmacao />} />
            <Route path="/cadastroUsuario" element={<CadastroUsuario />} />
            <Route path="/painelUsuario" element={<PainelUsuario />} />
            <Route path="/loginMK" element={<LoginMK />} />

            <Route path="/lojas/:id" element={<LojaDetalhes />} />
            <Route path="/produto/:productId" element={<Item />} />
            
          </Routes>
          {currentCall && <VetCall roomName={currentCall} onClose={handleCallClose} />}
        </Router>
        <PageFooter />
        <MenuMobile menuIsVisible={menuIsVisible} setMenuIsVisible={setMenuIsVisible} />
      </div>
    </>
  );
}

export default App;