import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Carteirinha from '../../Dashboard/PetCard/Carteirinha';
import CarteirinhaExemplo from '../../Dashboard/PetCard/CarteirinhaExemplo';

const QrCode = ({ petId }) => {
  const [pet, setPet] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (petId) {
      fetch(`https://vidapet.tech/api/DB/vidapettech/api_carteira_pet.php?petId=${petId}`)
        .then(response => response.json())
        .then(data => {
          if (data.error) {
            setError(data.error);
          } else {
            setPet(data);
          }
        })
        .catch(error => {
          setError("Erro ao buscar informações do pet.");
          console.error("Erro na requisição:", error);
        });
    } else {
      // Exibir a carteirinha de exemplo do Zeus
      setPet({
        petName: "Zeus",
        age: 4,
        breed: "Golden Retriever",
        // Outros campos necessários para exibir o exemplo do Zeus
      });
    }
  }, [petId]);

  const handleClose = () => {
    navigate('/RescueDashboard');
  };

  return (
    <QrCodeContainer>
      
      <ExplanationContainer>
        <h2>Como funciona o QR Code?</h2>
        <p>
          Se alguém escanear o QR Code da coleira do seu pet, ele será redirecionado para uma página como esta, onde poderá ver as informações essenciais do seu pet, como nome, raça, idade, e informações de contato do tutor.
        </p>
        <p>
          Esta funcionalidade é especialmente útil caso seu pet se perca. Certifique-se de que o QR Code esteja bem visível na coleira do seu pet para facilitar a identificação e o retorno seguro dele.
        </p>
        <p>
          Caso o QR Code tenha sido escaneado por você ou outra pessoa, as informações completas do pet serão exibidas aqui.
        </p>
        <p>
          Abaixo, você vê um exemplo de como as informações do seu pet serão exibidas utilizando o nosso mascote, Zeus.
        </p>
      </ExplanationContainer>

      {error ? (
        <ErrorContainer>
          <h2>Erro</h2>
          <p>{error}</p>
        </ErrorContainer>
      ) : petId && pet ? (
        <CarteirinhaContainer>
          <Carteirinha pet={pet} isInQrCodePage={!petId} />
        </CarteirinhaContainer>
      ) : (
        <CarteirinhaExemploContainer>
          <CarteirinhaExemplo isInQrCodePage={!petId} />
        </CarteirinhaExemploContainer>
      )}
    </QrCodeContainer>
  );
};

// Estilizações

const QrCodeContainer = styled.div`
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column; /* Garante que os elementos fiquem empilhados */
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #d32f2f;
  }
`;

const ExplanationContainer = styled.div`
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px; /* Adiciona espaçamento abaixo do texto explicativo */
  text-align: center;
`;

const CarteirinhaContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px; /* Adiciona espaço acima da carteirinha */
  width: 100%; /* Largura total para a carteirinha completa */
`;

const CarteirinhaExemploContainer = styled(CarteirinhaContainer)`
  width: 33%; /* Ajuste para 1/3 do tamanho normal */
  @media (max-width: 768px) {
    width: 90%; /* Set width to 90% on smaller screens like mobile */
  }
`;
const LoadingContainer = styled.div`
  text-align: center;
  padding: 20px;
`;

const ErrorContainer = styled.div`
  background-color: #ffcccc;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 600px;
  margin: 20px auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

export default QrCode;
