import React, { useState, useEffect } from 'react';

const ChatSystemNotifications = ({ userId, onNotificationSelect, onTermsAcceptanceRequired }) => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    fetchNotifications();
  }, [userId]); // Dependência adicionada

  const fetchNotifications = () => {
    fetch(`https://vidapet.tech/api/DB/vidapettech/get_notifications.php?recipientId=${userId}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Erro na resposta da API: ' + response.statusText);
        }
        return response.json();
      })
      .then(data => {
        setNotifications(data.notifications);
      })
      .catch(error => {
        console.error('Erro ao buscar notificações:', error);
      });
  };

  const handleNotificationClick = (notification) => {
    if (notification.nature === 'terms_acceptance') {
      // Tratamento especial para a notificação de aceitação dos termos
      onTermsAcceptanceRequired();
    } else {
      fetch('https://vidapet.tech/api/DB/vidapettech/update_notification.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          notificationId: notification.id,
          receivedId: userId
        }),
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            onNotificationSelect(notification);
          } else {
            console.error('Erro ao atualizar notificação:', data.error);
          }
        })
        .catch(error => {
          console.error('Erro ao atualizar notificação:', error);
        });
    }
  };

  return (
    <div className="notifications-section">
      <div className="notification-header">
        <h4>Notificações</h4>
      </div>
      <ul className="notification-list">
        {notifications.map(notification => (
          <li
            key={notification.id}
            className="notification-item"
            onClick={() => handleNotificationClick(notification)}
          >
            {notification.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ChatSystemNotifications;
