import React from 'react';
import styled from 'styled-components';

// Define um componente estilizado para o contêiner principal
const MarketplaceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

// Define um componente estilizado para a imagem
const Image = styled.img`
  width: 100%; /* Garante que a imagem se ajuste dinamicamente ao tamanho do contêiner pai */
  max-width: 600px; /* Define uma largura máxima para a imagem para evitar que ela fique muito grande */
`;

// Define um componente estilizado para o texto
const Text = styled.p`
  margin-top: 20px; /* Adiciona espaço entre a imagem e o texto */
  font-size: 34px;
}
`;

function MarketPlace() {
  return (
    <MarketplaceContainer>
      <Image
        src='https://img.freepik.com/vetores-premium/site-em-desenvolvimento-site-em-construcao_106788-2280.jpg'
        alt='Imagem'
      />
      <Text>Página em desenvolvimento</Text>
    </MarketplaceContainer>
  );
}

export default MarketPlace;
