import React, { useState } from 'react';
import styled from 'styled-components';
import { auth } from '../../firebase';
import { FaUser, FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';
import { RiCloseCircleFill } from 'react-icons/ri';
import { Link, useNavigate } from 'react-router-dom';
import { MdEmail } from "react-icons/md";
import axios from 'axios'; // Importando axios para a chamada da API

const Registro = ({ closeRegistro, closeUserMenu, setUser }) => {  // Adicionei a prop setUser
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState('');
  const [sucesso, setSucesso] = useState('');
  const navigate = useNavigate();

  const handleTogglePasswordVisibility = (field) => {
    if (field === 'password') {
      setShowPassword(!showPassword);
    } else if (field === 'confirmPassword') {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  const handleRegister = async () => {
    setError(''); // Limpar erros anteriores
  
    // Verificar se o email está no formato correto
    if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      setError('Por favor, insira um email válido.');
      return;
    }
  
    // Verificar se as senhas coincidem
    if (password !== confirmPassword) {
      setError('As senhas não coincidem.');
      return;
    }
  
    try {
      // Verificar no backend se o email já está cadastrado
      const response = await axios.post('https://vidapet.tech/api/Login/cadastroCheckEmail.php', { email });
  
      if (response.data.status === 'error') {
        setError('Email já está cadastrado. Faça login ou recupere a senha.');
        return;
      }
  
      // Se o email não estiver cadastrado, registrar no backend
      const userData = {
        email,
        displayName: userName,
        password, // Enviar a senha para o backend
      };
  
      const backendResponse = await axios.post('https://vidapet.tech/sync-user.php', userData);
  
      if (backendResponse.data.status === 'error') {
        setError('Erro ao registrar no backend: ' + backendResponse.data.message);
        return;
      }
  
      // Após o cadastro no backend, proceder com o registro no Firebase
      const { user } = await auth.createUserWithEmailAndPassword(email, password);
      await user.updateProfile({ displayName: userName });
  
      // Login automático após o cadastro
      const userCredential = await auth.signInWithEmailAndPassword(email, password);
      
      if (userCredential) {
        setSucesso('Cadastro realizado com sucesso.');
  
        // Reiniciar a página após o sucesso no registro e autenticação
        setTimeout(() => {
          window.location.reload();  // Recarrega a página para garantir que o menu de usuário logado seja exibido
        }, 2000);  // Aguarda 2 segundos para mostrar a mensagem de sucesso
      }
  
    } catch (error) {
      setError('Erro no processo de cadastro: ' + error.message);
    }
  };
  
  return (
    <Cont>
      <div className='wrapper'>
        <Link to="/home">
          <RiCloseCircleFill size={35} className='close' onClick={() => closeRegistro()} />
        </Link>
        <h1>Registrar</h1>
        <input
          type='text'
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
          placeholder='Nome completo'
        />
        <input
          type='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder='Email'
        />
        <input
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder='Senha'
        />
        <input
          type={showConfirmPassword ? 'text' : 'password'}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder='Confirmar senha'
        />
        <button onClick={handleRegister}>Registrar</button>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        {sucesso && <p style={{ color: 'green' }}>{sucesso}</p>}
      </div>
    </Cont>
  );
};

export default Registro;

const Cont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;

  .wrapper {
    background: white;
    padding: 20px;
    width: 90%;
    max-width: 400px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

    h1 {
      text-align: center;
      color: #333;
    }

    input {
      width: 100%;
      padding: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
      border-radius: 4px;
      border: 1px solid #ccc;
    }

    button {
      width: 100%;
      padding: 10px;
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 4px;
      margin-top: 10px;
      cursor: pointer;

      &:hover {
        background-color: #0056b3;
      }
    }

    .close {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
      color: #666;

      &:hover {
        color: #222;
      }
    }
  }
`;
