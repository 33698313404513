import React, { useState } from 'react';
import axios from 'axios';

const Login = ({ onLoginSuccess }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://vidapet.tech/MARKET/API/login.php', {
                email,
                password
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const data = response.data;

            if (data.success) {
                // Armazena o usuário e o loja_id no localStorage
                localStorage.setItem('user', JSON.stringify(data.user));
                localStorage.setItem('loja_id', data.user.loja_id); // Armazena o loja_id
                console.log('Usuário armazenado:', data.user);
                console.log('loja_id armazenado:', data.user.loja_id);
                onLoginSuccess(data.user); // Envia os dados do usuário para o componente pai
            } else {
                setMessage(data.error);
            }
        } catch (error) {
            console.error('Erro ao conectar com o servidor:', error);
            setMessage('Erro ao conectar com o servidor.');
        }
    };

    return (
        <div>
            <h1>Login do Marketplace</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Email:</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Senha:</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit">Entrar</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
};

export default Login;
