import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './estilo.css'; // Certifique-se de importar o CSS

function CadastroLoja() {
    const location = useLocation();
    const navigate = useNavigate();
    const { user_id } = location.state || {};  // Pegando o user_id da navegação
    const [nomeLoja, setNomeLoja] = useState('');
    const [descricao, setDescricao] = useState('');
    const [foto, setFoto] = useState(null);
    const [endereco, setEndereco] = useState('');
    const [telefone, setTelefone] = useState('');
    const [categoria, setCategoria] = useState('');

    const categorias = ['Loja de Ração', 'Banho e Tosa', 'PetShop'];

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('name_loja', nomeLoja);
        formData.append('description', descricao);
        formData.append('foto', foto);
        formData.append('endereco', endereco);
        formData.append('telefone', telefone);
        formData.append('categoria', categoria);
        formData.append('user_id', user_id);  // Adiciona o user_id ao FormData

        try {
            const response = await fetch('https://vidapet.tech/MARKET/API/adicionar_loja.php', {
                method: 'POST',
                body: formData,
                credentials: 'include'
            });

            const result = await response.json();
            if (result.success) {
                alert('Loja adicionada com sucesso!');
                navigate('/confirmacao');
            } else {
                alert('Erro ao adicionar loja.');
            }
        } catch (error) {
            console.error('Erro ao enviar dados:', error);
            alert('Erro ao enviar dados. Por favor, tente novamente.');
        }
    };

    return (
        <div className="container">
            <div className="form-column">
                <h2>Cadastro - Etapa 3</h2>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label>Foto do Logo:</label>
                        <input
                            type="file"
                            onChange={(e) => setFoto(e.target.files[0])}
                            required
                        />
                    </div>
                    <div>
                        <label>Nome da Loja:</label>
                        <input
                            type="text"
                            value={nomeLoja}
                            onChange={(e) => setNomeLoja(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>Descrição:</label>
                        <textarea
                            value={descricao}
                            onChange={(e) => setDescricao(e.target.value)}
                            required
                        ></textarea>
                    </div>
                    <div>
                        <label>Endereço:</label>
                        <input
                            type="text"
                            value={endereco}
                            onChange={(e) => setEndereco(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>Telefone:</label>
                        <input
                            type="text"
                            value={telefone}
                            onChange={(e) => setTelefone(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>Categoria:</label>
                        <select
                            value={categoria}
                            onChange={(e) => setCategoria(e.target.value)}
                            required
                        >
                            <option value="">Selecione uma categoria</option>
                            {categorias.map(cat => (
                                <option key={cat} value={cat}>{cat}</option>
                            ))}
                        </select>
                    </div>
                    <button type="submit">Criar Loja</button>
                </form>
            </div>
        </div>
    );
}

export default CadastroLoja;
