// src/pages/Login/userService.js
import axios from 'axios';

export const getUserIdFromFirebaseId = async (firebaseId) => {
  try {
    const response = await axios.get(`https://vidapet.tech/sync-user.php?firebaseId=${firebaseId}`);
    const userId = response.data.userId;
    return userId;
  } catch (error) {
    console.error('Erro ao consultar UserID:', error);
    return null;
  }
};
