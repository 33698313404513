import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const GamificationPoints = () => {
  const [points, setPoints] = useState(0);
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    // Fetch gamification points and activities from the backend
    axios.get('/api/gamification')
      .then(response => {
        setPoints(response.data.points);
        setActivities(response.data.activities);
      })
      .catch(error => console.error("Error fetching gamification points:", error));
  }, []);

  return (
    <Container>
      <h2>Gamification Points: {points}</h2>
      <ul>
        {activities.map(activity => (
          <li key={activity.id}>{activity.description} - {activity.points} points</li>
        ))}
      </ul>
    </Container>
  );
};

export default GamificationPoints;

const Container = styled.div`
  padding: 20px;
  background: #f8f8f8;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;

  li {
    padding: 8px 0;
    border-bottom: 1px solid #ddd;
  }
`;
