import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import JitsiMeeting from '../Components/VetCalls/JitsiMeeting';
import HealthEventForm from '../Components/HealthEventForm';
import VetList from '../Components/VetCalls/VetList';

const VetDashboard = ({ selectedPetId }) => {
  const [vetId, setVetId] = useState('');
  const [error, setError] = useState('');
  const [roomName, setRoomName] = useState('');
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [rooms, setRooms] = useState([]);
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const correctPassword = 'vet901';
  const fetchRooms = useCallback(async () => {
    try {
      const response = await axios.get(`https://vidapet.tech/api/calls/get_rooms.php?vetId=${vetId}`);
      setRooms(response.data);
    } catch (error) {
      console.error('Erro ao buscar salas:', error);
      setError('Erro ao buscar salas.');
    }
  }, [vetId]);

  useEffect(() => {
    if (vetId) {
      fetchRooms();
    }
  }, [vetId, fetchRooms]);
  const handleCreateRoom = async () => {
    try {
      const response = await axios.post('https://vidapet.tech/api/calls/create_room.php', { vetId });
      if (response.data.status === 'success') {
        setRoomName(response.data.roomName);
        setError('');
        alert('Sala criada com sucesso. Aguardando usuários.');
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      console.error('Erro ao criar a sala:', error);
      setError('Erro ao criar a sala.');
    }
  };

  const handleSelectRoom = (roomName) => {
    setRoomName(roomName);
    fetchRoomDetails(roomName);
  };

  const fetchRoomDetails = async (roomName) => {
    try {
      const response = await axios.get(`https://vidapet.tech/api/calls/get_room_details.php?roomName=${roomName}`);
      setSelectedRoom(response.data);
    } catch (error) {
      console.error('Erro ao buscar detalhes da sala:', error);
      setError('Erro ao buscar detalhes da sala.');
    }
  };
  const handleAddHealthEvent = async (eventDescription) => {
    if (selectedRoom || selectedPetId) {
      try {
        const eventType = 'Atendimento';
        const userDate = new Date().toISOString().split('T')[0];

        const formData = new FormData();
        formData.append('eventType', eventType);
        formData.append('eventDescription', eventDescription);
        formData.append('petId', selectedRoom?.pet_id || selectedPetId);
        formData.append('userDate', userDate);

        const response = await axios.post('https://vidapet.tech/health-events.php', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        console.log('Evento de saúde adicionado com sucesso', response.data);
      } catch (error) {
        console.error('Erro ao adicionar evento de saúde:', error);
      }
    }
  };

  const handlePasswordSubmit = () => {
    if (password === correctPassword) {
      setIsAuthenticated(true);
    } else {
      setError('Senha incorreta.');
    }
  };

  if (!isAuthenticated) {
    return (
      <div>
        <h1>Painel de Veterinário</h1>
        <label htmlFor="passwordInput">Digite a senha para acessar:</label>
        <input
          type="password"
          id="passwordInput"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Insira a senha"
        />
        <button onClick={handlePasswordSubmit}>Entrar</button>
        {error && <p>{error}</p>}
      </div>
    );
  }

  return (
    <div>
      <h1>Painel de Veterinário</h1>
      <div>
        <label htmlFor="vetIdInput">Digite seu ID de Veterinário:</label>
        <input
          type="text"
          id="vetIdInput"
          value={vetId}
          onChange={(e) => setVetId(e.target.value)}
          placeholder="Insira seu ID"
        />
        <button onClick={fetchRooms}>Mostrar Salas</button>
      </div>
      {vetId && <VetList rooms={rooms} onSelectRoom={handleSelectRoom} />}
      <button onClick={handleCreateRoom}>Criar Nova Sala</button>
      {roomName && <JitsiMeeting roomName={roomName} />}
      {(selectedRoom || selectedPetId) && (
        <div>
          <h2>Carteirinha Digital do Pet</h2>
          <HealthEventForm petId={selectedRoom?.pet_id || selectedPetId} />
          <button onClick={() => handleAddHealthEvent('Descrição do evento')}>Adicionar Evento de Saúde</button>
        </div>
      )}
      {error && <p>{error}</p>}
    </div>
  );
};

export default VetDashboard;
