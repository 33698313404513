import React from 'react';
import './Telemedicine.css';
import heroImage from '../../imagens/TeleVet001.webp'; // Primeira imagem real
import secondaryImage from '../../imagens/TeleVet002.webp'; // Segunda imagem real

const Telemedicine = () => {
  const handleWhatsAppClick = (type) => {
    let message = '';
    if (type === 'owner') {
      message = encodeURIComponent(
        `Quero Participar do Projeto Piloto Video Chamada Veterinaria VidaPet.Tech:\n` +
        `- Nome Completo:\n` +
        `- Cidade:\n` +
        `- Email:\n` +
        `- WhatsApp para contato:`
      );
    } else if (type === 'vet') {
      message = encodeURIComponent(
        `Sou Veterinário/Clínica e desejo participar do Projeto Piloto Video Chamada Veterinaria VidaPet.Tech:\n` +
        `- Nome Completo:\n` +
        `- Cidade:\n` +
        `- Email:\n` +
        `- WhatsApp para contato:\n` +
        `- Nome da Clínica (se aplicável):`
      );
    }
    window.open(`https://wa.me/5521982992635?text=${message}`, '_blank');
  };

  return (
    <div className="telemedicine-container">
      <div className="hero-section">
        <img src={heroImage} alt="Teleconsulta Veterinária" className="hero-image" />
        <h1>Teleconsulta Veterinária VidaPet.Tech</h1>
        <p>Cuidado veterinário a uma chamada de distância!</p>
      </div>

      <div className="benefits-section">
        <h2>Por que escolher a Telemedicina VidaPet.Tech?</h2>
        <ul>
          <li><i className="icon-check"></i>Atendimento 24/7.</li>
          <li><i className="icon-check"></i>Consultas Personalizadas.</li>
          <li><i className="icon-check"></i>Receitas e Exames Seguros.</li>
          <li><i className="icon-check"></i>Acompanhamento de Rotina.</li>
        </ul>
      </div>

      <div className="cta-section">
        <img src={secondaryImage} alt="Pet Care" className="cta-image" />
        <h2>Participe do Projeto Piloto!</h2>
        <p>
          Teste a nossa Telemedicina Veterinária. Se você tem um pet ou é um veterinário interessado, clique no botão abaixo para participar.
        </p>
        <button className="whatsapp-button" onClick={() => handleWhatsAppClick('owner')}>
          Sou Dono de Pet
        </button>
        <button className="whatsapp-button" onClick={() => handleWhatsAppClick('vet')}>
          Sou Veterinário/Clínica
        </button>
      </div>
    </div>
  );
};

export default Telemedicine;
