import React from 'react';
import './ProductDetail.css'; // Importar o arquivo CSS para o carrossel

const ImageCarousel = ({ product, selectedImage, setSelectedImage }) => {
  if (!product) return null;

  return (
    <div className="image-carousel">
      <div className="thumbnails">
        {product.image && (
          <img
            src={`https://vidapet.tech/MARKET/Produtos/${product.image}`}
            alt="Produto"
            className={`thumbnail ${selectedImage === product.image ? 'active' : ''}`}
            onClick={() => setSelectedImage(product.image)}
          />
        )}
      </div>
      <div className="selected-image">
        <img src={`https://vidapet.tech/MARKET/Produtos/${selectedImage}`} alt="Produto" />
      </div>
    </div>
  );
};

export default ImageCarousel;
