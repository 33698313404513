import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUserIdFromFirebaseId } from './userService';

// Ação assíncrona Thunk para obter o userId e atualizar o estado
export const fetchUserId = createAsyncThunk(
  'user/fetchUserId',
  async (firebaseId, thunkAPI) => {
    const userId = await getUserIdFromFirebaseId(firebaseId);
    return userId; // A promessa retorna o userId, que será usado no fulfilled reducer
  }
);

const initialState = {
  id: null,
  name: "",
  email: "",
  photo: "",
  status: 'idle', // adiciona um campo de status para rastrear o estado da requisição
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserLoginDetails: (state, action) => {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.photo = action.payload.photo;
    },
    setSignOutState: (state) => {
      state.id = null;
      state.name = "";
      state.email = "";
      state.photo = "";
    },
    // Outros reducers aqui...
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserId.pending, (state) => {
        // Quando a requisição está pendente, você pode, por exemplo, definir o estado para loading
        state.status = 'loading';
      })
      .addCase(fetchUserId.fulfilled, (state, action) => {
        // Quando a requisição é bem-sucedida (fulfilled), atualiza o estado com o userId
        state.id = action.payload; // o payload é o valor retornado do thunk
        state.status = 'succeeded';
      })
      .addCase(fetchUserId.rejected, (state, action) => {
        // Quando a requisição é rejeitada, você pode tratar erros aqui
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { setUserLoginDetails, setSignOutState } = userSlice.actions;
export const selectUserId = (state) => state.user.id;
export const selectUserName = (state) => state.user.name;
export const selectUserEmail = (state) => state.user.email;
export const selectUserPhoto = (state) => state.user.photo;
export const selectUserStatus = (state) => state.user.status; // Selector para obter o estado da requisição
export const selectUserError = (state) => state.user.error; // Selector para obter a mensagem de erro, se houver

export default userSlice.reducer;
