import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import WebcamCapture from './Components/camera';
import RescueView from '../Resgate/RescueView';
import PetLostForm from './Components/PetLostForm';
import QrCode from './QrCode/QrCode';
import { FaSearch, FaCamera, FaPaw, FaClipboard } from 'react-icons/fa';
import { BsQrCodeScan } from "react-icons/bs";

function RescueDashboard() {
  const [activeComponent, setActiveComponent] = useState('rescueCases');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const petId = queryParams.get('qrcode'); // Captura o parâmetro `qrcode` da URL

  useEffect(() => {
    if (petId) {
      // Se petId estiver presente na URL, ativa a aba QrCode
      setActiveComponent('qrCode');
    }
  }, [petId]);

  const renderContent = () => {
    switch (activeComponent) {
      case 'rescueCases':
        return <RescueView />;
      case 'qrCode':
        return <QrCode petId={petId} />;
      case 'webcam':
        return (
          <WebcamCapture
            closeWebcam={() => setActiveComponent('rescueCases')}
          />
        );
      case 'petLostForm':
        return <PetLostForm />;
      default:
        return <RescueView />;
    }
  };

  return (
    <DashboardContainer>
      
      <Sidebar>
        <SidebarButton onClick={() => setActiveComponent('rescueCases')}>
          <IconWrapper><FaClipboard /></IconWrapper> Resgates Recentes
        </SidebarButton>
        <SidebarButton onClick={() => setActiveComponent('qrCode')}>
          <IconWrapper><BsQrCodeScan /></IconWrapper> Escanear QR Code
        </SidebarButton>
        <SidebarButton onClick={() => setActiveComponent('webcam')}>
          <IconWrapper><FaCamera /></IconWrapper> Registrar Pet Perdido
        </SidebarButton>        
      </Sidebar>

      <ContentContainer>
        {renderContent()}
      </ContentContainer>

    </DashboardContainer>
  );
}


const DashboardContainer = styled.div`
  display: flex;
  height: 100vh;
  background-color: #f7f9fc;  
`;

const Sidebar = styled.div`
 width: 220px; /* Largura ajustada da sidebar */
  background-color: #e3f2fd;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);

  @media (max-width: 750px) {

  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  position: fixed; /* Fica fixo na parte inferior em telas menores */
  width: 100%;
  bottom: 0;
    box-shadow: none;
    z-index: 2000;
  }
`;

const SidebarButton = styled.button`
   background: linear-gradient(45deg, #42a5f5, #1e88e5);
  color: #ffffff;
  width: 100%;
  height: 60px;
  margin: 10px 0;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  text-align: left;
  display: flex;
  align-items: center;
  padding-left: 15px;
  transition: background 0.3s, transform 0.2s, box-shadow 0.2s;


  &:hover {
    background: linear-gradient(45deg, #1e88e5, #1565c0);
    transform: translateX(5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 750px) {
    width: 7rem; /* Botões têm largura automática no mobile */
    height: auto;
    font-size: 14px;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    left:2px;
  }
`;

const IconWrapper = styled.div`
  margin-right: 10px;
  font-size: 20px;

  @media (max-width: 750px) {
    margin-right: 0;
    font-size: 18px;
  }
`;

const ContentContainer = styled.div`
 flex-grow: 1; /* O conteúdo cresce para ocupar o espaço restante */
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  font-family: 'Roboto', sans-serif;
  margin-left: 20px; /* Espaçamento reduzido entre a sidebar e o conteúdo */
  width: calc(100% - 240px); /* Ajuste para ocupar o espaço restante após a sidebar */

  @media (max-width: 750px) {
    padding-bottom: 60px; /* Adiciona espaço para a barra de navegação fixa no mobile */
  }
`;

export default RescueDashboard;