import React, { useState, useEffect, useRef } from 'react';
import { RiCloseCircleFill } from 'react-icons/ri';
import { MapContainer, TileLayer, Marker, useMapEvents, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import HeartExplosion from './HeartExplosion'; // Animação ao finalizar
import './PetLostForm.css'; // Estilos
import { CloseButton, ButtonRes, ButtonMap} from './CSS/styleCamera';
import userLocationIconImage from '../../../imagens/user-location-icon.png';


// Componente para manipular a localização clicada no mapa
function LocationMarker({ setLocation }) {
  useMapEvents({
    click(e) {
      console.log("Mapa clicado. Latitude e Longitude: ", e.latlng);
      setLocation(e.latlng);
    },
    touchstart(e) {
      console.log("Mapa tocado. Latitude e Longitude: ", e.latlng);
      setLocation(e.latlng);
    }
  });
  return null;
}

function PetLostForm({ closeForm }) {
  const [step, setStep] = useState(1); // Controle da etapa atual
  const [selectedImage, setSelectedImage] = useState(null); // Imagem do pet (arquivo)
  const [imagePreview, setImagePreview] = useState(null); // URL de visualização da imagem
  const [location, setLocation] = useState(null); // Localização selecionada no mapa
  const [initialPosition, setInitialPosition] = useState([-15.7941, -47.8825]); // Posição inicial padrão (Brasília)
  const [comments, setComments] = useState(''); // Comentários do formulário
  const [showAnimation, setShowAnimation] = useState(false); // Controle da animação ao finalizar
  const [useAddressForm, setUseAddressForm] = useState(false); // Controle do uso do formulário de endereço
  const [address, setAddress] = useState({ state: '', city: '', neighborhood: '', street: '' }); // Endereço do formulário
  const [states, setStates] = useState([]); // Lista de estados
  const [cities, setCities] = useState([]); // Lista de cidades
  const [petType, setPetType] = useState('');
  const fileInputRef = useRef(null); // Referência para o input de imagem


  const userLocationIcon = L.icon({
    iconUrl: userLocationIconImage,
    iconSize: [38, 38],
    iconAnchor: [19, 38]
  });



  // Carregar a localização do usuário
  useEffect(() => {
    console.log("Tentando obter a localização do usuário...");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          console.log("Localização do usuário obtida: ", latitude, longitude);
          setInitialPosition([latitude, longitude]); // Define a localização do usuário
        },
        (error) => {
          console.error("Erro ao obter localização, usando fallback para Brasília:", error);
        }
      );
    }
  }, []);

  // Função para carregar os estados da API do IBGE
  useEffect(() => {
    fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .then(response => response.json())
      .then(data => {
        setStates(data);
      })
      .catch(error => console.error('Erro ao carregar estados:', error));
  }, []);


  // Função para carregar as cidades com base no estado selecionado
  const loadCities = (stateId) => {
    fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateId}/municipios`)
      .then(response => response.json())
      .then(data => {
        setCities(data);
      })
      .catch(error => console.error('Erro ao carregar cidades:', error));
  };

  // Manipulador de mudança de imagem
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    console.log("Imagem selecionada pelo usuário:", file);
    if (file) {
      setSelectedImage(file); // Armazena o arquivo de imagem
      setImagePreview(URL.createObjectURL(file)); // Armazena a URL da visualização da imagem
    }
  };

  // Manipulador de mudança de comentário
  const handleCommentChange = (event) => {
    console.log("Comentário adicionado: ", event.target.value);
    setComments(event.target.value);
  };

  // Manipulador de mudanças no endereço
  const handleAddressChange = (event) => {
    const { name, value } = event.target;
    console.log(`Endereço atualizado. Campo: ${name}, Valor: ${value}`);
    setAddress((prevState) => ({ ...prevState, [name]: value }));

    if (name === 'state') {
      loadCities(value); // Carrega as cidades do estado selecionado
    }
  };

  // Função para obter as coordenadas baseadas no endereço usando uma API de geocodificação (como OpenStreetMap)
  const getCoordinatesFromAddress = async (address) => {
    const { street, neighborhood, city, state } = address;
    const fullAddress = `${street}, ${neighborhood}, ${city}, ${state}, Brasil`;
    const encodedAddress = encodeURIComponent(fullAddress);

    const url = `https://nominatim.openstreetmap.org/search?q=${encodedAddress}&format=json`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.length > 0) {
        const { lat, lon } = data[0];
        return { lat: parseFloat(lat), lng: parseFloat(lon) };
      } else {
        console.warn("Nenhuma coordenada encontrada para o endereço fornecido.");
        return null;
      }
    } catch (error) {
      console.error("Erro ao buscar coordenadas do endereço:", error);
      return null;
    }
  };

  // Envio dos dados para o backend
  const handleSendData = async () => {
    console.log("Iniciando o envio dos dados...");
    if (!selectedImage) {
      alert("Por favor, selecione uma imagem.");
      console.log("Erro: Imagem não selecionada.");
      return;
    }
    let petLocation = location;

    if (!petLocation && useAddressForm) {
      petLocation = await getCoordinatesFromAddress(address);

      if (!petLocation) {
        // Se não houver localização exata, ajusta a localização do usuário com um deslocamento de 2 km
        const userLat = initialPosition[0] + (Math.random() * 0.018 - 0.009); // Aproximadamente 2 km de diferença
        const userLng = initialPosition[1] + (Math.random() * 0.018 - 0.009); // Aproximadamente 2 km de diferença
        petLocation = { lat: userLat, lng: userLng };
        console.log("Usando coordenadas ajustadas com base na localização do usuário:", petLocation);
      }
    }

    if (!petLocation) {
      alert("Por favor, selecione uma localização no mapa ou preencha o formulário de endereço.");
      console.log("Erro: Localização ou endereço não fornecido.");
      return;
    }

    if (!comments.trim()) {
      alert("Por favor, adicione um comentário.");
      console.log("Erro: Comentário não fornecido.");
      return;
    }

    console.log("Dados validados. Preparando o FormData...");
    const formData = new FormData();
    formData.append('latitude', petLocation.lat);
    formData.append('longitude', petLocation.lng);
    formData.append('capture_datetime', new Date().toISOString());
    formData.append('comments', comments);
    formData.append('image', selectedImage);    
    formData.append('pet_type', petType);


    try {
      console.log("Enviando dados para o servidor...");
      const response = await fetch('https://vidapet.tech/upload.php', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        console.log("Dados enviados com sucesso!");
        setShowAnimation(true);
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      } else {
        console.error("Erro ao enviar dados para o servidor.");
      }
    } catch (error) {
      console.error("Erro na requisição:", error);
    }
  };

  // Navegação entre etapas
  const handleNextStep = () => {
    console.log("Avançando para a próxima etapa. Etapa atual:", step);
    // Verifica a etapa atual antes de avançar
    if (step === 2 && !selectedImage) {
      alert("Por favor, selecione uma imagem.");
    } else if (step === 3 && useAddressForm && (!address.street || !address.city || !address.state)) {
      alert("Por favor, preencha todos os campos de endereço.");
    } else {
      setStep(step + 1);
    }
  };

  const handlePreviousStep = () => {
    console.log("Voltando para a etapa anterior. Etapa atual:", step);
    setStep(step - 1);
  };

  // Função para pular o mapa e ir direto ao formulário de endereço
  const handleSkipMap = () => {
    console.log("Usuário escolheu pular o mapa e usar o formulário de endereço.");
    setUseAddressForm(true);
    setStep(3); // Vai diretamente para a etapa de preenchimento do endereço
  };

  const handleForMap = () => {
    console.log("Usuário escolheu voltar para o mapa.");
    setUseAddressForm(false);
    setStep(3); // Vai diretamente para a etapa de preenchimento do endereço
  };

  // Renderização das etapas do formulário
  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="step step-intro">
          <CloseButton size={30} onClick={closeForm} />
      
          <h2>Encontrar Seu Pet</h2>
      <br></br>
          <p>
              Estamos aqui para ajudar a encontrar seu amiguinho! Para começar, por favor, envie uma imagem recente do seu pet e informe a última localização conhecida no mapa, ou insira o endereço manualmente.
          </p>
          <br></br>
          <p>
              Com essas informações, poderemos auxiliar na busca de forma mais eficaz.
          </p>
          <br></br>
          <p className="comfort-message">
              Sabemos como esse momento pode ser difícil, e queremos garantir que faremos o possível para ajudar. Vamos começar essa jornada juntos.
          </p>
          <br></br>
          <ButtonRes onClick={handleNextStep}>Iniciar</ButtonRes>
      </div>
      
        );
      case 2:
        return (
         <div className="card">
    <CloseButton size={30} onClick={closeForm} />
<br></br>
    <h2>Envie a Foto Mais Atual do Seu Pet</h2>

    <p>
        Para ajudar na identificação do seu pet, por favor, envie uma imagem recente. Clique no botão abaixo para selecionar uma foto do seu dispositivo.
    </p>

    <input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        ref={fileInputRef}
        style={{ display: 'none' }}
    />

    <ButtonMap onClick={() => fileInputRef.current.click()} className="upload-button">
        Carregar Foto
    </ButtonMap>

    {imagePreview && (
        <div className="image-preview-container">
            <img className="image-preview" src={imagePreview} alt="Imagem do Pet" />
        </div>
    )}

    <div className="navigation-buttons">
        <ButtonRes onClick={handlePreviousStep} className="navigation-button">
            Voltar
        </ButtonRes>
        <ButtonRes onClick={handleNextStep} className="navigation-button">
            Próximo
        </ButtonRes>
    </div>
</div>

        );
      case 3:
        return (
          <div className="card">
            <CloseButton size={30} onClick={closeForm} />
            <h2>Selecione a Localização</h2>
            {!useAddressForm ? (
              <>
                <div className="map-picker-container" style={{ height: '300px', width: '100%' }}>
                  <MapContainer center={initialPosition} zoom={13} style={{ height: '100%', width: '100%' }}>
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                      {location && (
                <Marker position={location} icon={userLocationIcon}>
                    <Popup>
                        Localização atual
                    </Popup>
                </Marker>
            )}


                    <LocationMarker setLocation={setLocation} />
                  </MapContainer>
                </div>
                <ButtonMap onClick={handleSkipMap}>Pular Mapa e Usar Formulário de Endereço</ButtonMap>
              </>
            ) : (
              <div>
    <br></br>
    <p>Para garantir que possamos encontrar o seu pet com precisão, por favor, complete os campos abaixo:</p>

    <label htmlFor="state">
        <span>Selecione o Estado:</span>
        <select
            id="state"
            name="state"
            value={address.state}
            onChange={handleAddressChange}
            required
        >
            <option value="">Escolha o Estado</option>
            {states.map((state) => (
                <option key={state.id} value={state.sigla}>
                    {state.nome}
                </option>
            ))}
        </select>
    </label>

    <label htmlFor="city">
        <span>Selecione a Cidade:</span>
        <select
            id="city"
            name="city"
            value={address.city}
            onChange={handleAddressChange}
            required
            disabled={!address.state}
        >
            <option value="">Escolha a Cidade</option>
            {cities.map((city) => (
                <option key={city.id} value={city.nome}>
                    {city.nome}
                </option>
            ))}
        </select>
    </label>

    <label htmlFor="neighborhood">
        <span>Bairro:</span>
        <input
            id="neighborhood"
            type="text"
            name="neighborhood"
            placeholder="Digite o Bairro"
            value={address.neighborhood}
            onChange={handleAddressChange}
        />
    </label>

    <label htmlFor="street">
        <span>Rua:</span>
        <input
            id="street"
            type="text"
            name="street"
            placeholder="Digite a Rua"
            value={address.street}
            onChange={handleAddressChange}
        />
    </label>

    <div className="navigation-buttons">
              <ButtonRes onClick={handleForMap}>Voltar</ButtonRes>
              <ButtonRes onClick={handleNextStep}>Próximo</ButtonRes>
            </div>

</div>

            )}
            <div className="navigation-buttons">
              <ButtonRes onClick={handlePreviousStep}>Voltar</ButtonRes>
              <ButtonRes onClick={handleNextStep}>Próximo</ButtonRes>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="step step-description">
          <CloseButton size={30} onClick={closeForm} />
      <br></br>
          <h2>Compartilhe Detalhes do Seu Amigo</h2>
      <br></br>
          <p>
              Por favor, forneça uma descrição detalhada do seu pet para ajudar na identificação e busca. Quanto mais informações você puder fornecer, melhor.
          </p>

          <div>
                <label htmlFor="petType">Tipo de Pet:</label>
                <select
                  id="petType"
                  value={petType}
                  onChange={(e) => setPetType(e.target.value)}
                  style={{ marginBottom: '10px', padding: '5px', width: '100%' }}
                >
                  <option value="">Selecione o tipo de pet</option>
                  <option value="cachorro">Cachorro</option>
                  <option value="gato">Gato</option>
                  <option value="outros">Outros</option>
                </select>
              </div>
              
          <textarea
              className="textarea"
              value={comments}
              onChange={handleCommentChange}
              placeholder="Digite uma descrição"
              required
          />
      
          <div className="navigation-buttons">
              <ButtonRes onClick={handlePreviousStep} className="navigation-button">
                  Voltar
              </ButtonRes>
              <ButtonRes onClick={handleSendData} className="navigation-button">
                  Finalizar
              </ButtonRes>
          </div>
      </div>
      
        );
      default:
        return null;
    }
  };

  return (
    <div className="modal">
      {showAnimation && <HeartExplosion />} {/* Mostrar animação se estiver no final */}
      <div className="content">
        {renderStep()} {/* Renderiza a etapa atual */}
      </div>
    </div>
  );
}

export default PetLostForm;
