import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const HeartExplosion = ({ onAnimationComplete }) => {
  useEffect(() => {
    const animationDuration = 3000; // Duração da animação em milissegundos
    const closeDelay = 1000; // Tempo para fechar a animação após terminar

    // Após o tempo de duração da animação, define isAnimationComplete como true
    const timeoutId = setTimeout(() => {
      onAnimationComplete();
    }, animationDuration + closeDelay);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [onAnimationComplete]);

  return (
    <Container>
      <PointsMessage>
        {['Você', 'ganhou', '10', 'pontos!'].map((word, index) => (
          <Word key={index} delay={index * 0.3}>{word}</Word>
        ))}
      </PointsMessage>
      {[...Array(10)].map((_, index) => (
        <Firework key={index} index={index} />
      ))}
    </Container>
  );
};

const explodeAnimation = keyframes`
  0% {
    opacity: 1;
    transform: scale(0);
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
`;

const wordAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px) scale(0.8);
  }
  50% {
    opacity: 1;
    transform: translateY(0) scale(1.2);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`;

const backgroundFade = keyframes`
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.8);
  }
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${backgroundFade} 0.5s ease forwards;
  pointer-events: none;
  z-index: 9999;
`;

const PointsMessage = styled.div`
  display: flex;
  gap: 12px;
  font-size: 32px;
  color: #ff6347;
  font-weight: bold;
  transform: scale(1.5);
  animation: ${wordAnimation} 1s ease forwards;
`;

const Word = styled.span`
  opacity: 0;
  animation: ${wordAnimation} 0.7s ease forwards;
  animation-delay: ${({ delay }) => delay}s;
`;

const fireworkColors = ['#ff6347', '#ffcc00', '#33cc33', '#3399ff', '#ff66cc'];

const Firework = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: ${({ index }) => fireworkColors[index % fireworkColors.length]};
  border-radius: 50%;
  animation: ${explodeAnimation} 1s ease-out infinite;
  animation-delay: ${({ index }) => index * 0.2}s;
  top: ${({ index }) => 10 + (index * 10)}%;
  left: ${({ index }) => 20 + (index * 7)}%;
`;

export default HeartExplosion;
