import { jsPDF } from "jspdf";
import TagStory01 from '../../../imagens/TagStory01.png';
import TagStory02 from '../../../imagens/TagStory02.png';
import TagStory03 from '../../../imagens/TagStory03.png';
import TagStory04 from '../../../imagens/TagStory04.png';
import { QRCodeCanvas } from 'qrcode.react'; // Para gerar o QR Code no canvas
import { createRoot } from 'react-dom/client'; // Substituir ReactDOM.render por createRoot

const generateStorytellingPDF = async (qrCodeUrl, petId) => {
  const doc = new jsPDF('portrait', 'mm', 'a4');

  try {
    // Gerar o QR Code como uma imagem base64 diretamente usando o canvas
    const qrCodeDataUrl = await generateQRCodeToBase64(qrCodeUrl);

    // Escolher uma história aleatória
    const storyNumber = Math.floor(Math.random() * 3) + 1;

    switch (storyNumber) {
      case 1:
        generateStory1(doc, qrCodeDataUrl);
        break;
      case 2:
        generateStory2(doc, qrCodeDataUrl);
        break;
      case 3:
        generateStory3(doc, qrCodeDataUrl);
        break;
      default:
        generateStory1(doc, qrCodeDataUrl); // Fallback padrão
        break;
    }

    // Baixar o PDF
    doc.save(`Instrucoes_VidaPet_${petId}.pdf`);
  } catch (error) {
    console.error('Erro ao carregar imagem em Base64:', error);
  }
};

// Função para gerar o QR Code diretamente em um canvas e obter sua imagem base64
const generateQRCodeToBase64 = (qrCodeUrl) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const qrCodeWrapper = document.createElement('div'); // Criar um elemento div para renderizar o QRCodeCanvas
    document.body.appendChild(qrCodeWrapper); // Adicionar o div temporariamente no DOM

    const root = createRoot(qrCodeWrapper); // Substituir ReactDOM.render por createRoot
    root.render(<QRCodeCanvas value={qrCodeUrl} size={100} />);

    setTimeout(() => {
      const qrCodeCanvas = qrCodeWrapper.querySelector('canvas'); // Encontrar o canvas dentro do wrapper

      if (!qrCodeCanvas) {
        reject('Erro ao gerar o QR Code no canvas.');
        return;
      }

      // Renderizar o canvas do QR Code
      canvas.width = qrCodeCanvas.width;
      canvas.height = qrCodeCanvas.height;
      ctx.drawImage(qrCodeCanvas, 0, 0);
      resolve(canvas.toDataURL('image/png')); // Retornar base64

      // Remover o div temporário após a conversão
      qrCodeWrapper.remove();
    }, 1000); // Aguardar um segundo para garantir que o QRCodeCanvas esteja totalmente renderizado
  });
};

// Função para adicionar o QR Code e o texto explicativo ao PDF
const addQRCodeWithExplanation = (doc, imgData) => {
  const qrCodeX = 150; // Posição horizontal do QR Code
  const qrCodeY = 260; // Ajustei a posição vertical para evitar sobreposição
  const qrCodeSize = 10; // Tamanho reduzido do QR Code

  // Adicionar QR Code
  doc.addImage(imgData, 'PNG', qrCodeX, qrCodeY, qrCodeSize, qrCodeSize);

  // Adicionar texto explicativo à esquerda do QR Code
  const textX = 15; // Posição horizontal do texto
  const textY = 260; // Ajustei a posição vertical do texto para ficar ao lado do QR Code

  doc.setFontSize(12);
  doc.text("Recorte essa TagQRCode e insira na Tag VidaPet.Tech.", textX, textY);
  doc.text("Se alguém ler a tag na coleira do seu Pet,", textX, textY + 10);
  doc.text("você receberá uma notificação imediatamente.", textX, textY + 20);
};

// Primeira história
const generateStory1 = (doc, imgData) => {
  doc.setFontSize(12);
  doc.setTextColor(0, 0, 0);

  // Imagem 1 e texto à direita
  doc.addImage(TagStory01, 'PNG', 15, 30, 40, 40);
  doc.text("Era uma vez um pet que ganhou uma tag especial...", 60, 50);

  // Texto à esquerda e imagem 2 à direita
  doc.text("Essa tag tinha um QR Code mágico que, ao ser lido,", 15, 90);
  doc.text("revelava as informações essenciais do pet.", 15, 100);
  doc.addImage(TagStory02, 'PNG', 60, 110, 40, 40);

  // Imagem 3 e texto à direita
  doc.addImage(TagStory03, 'PNG', 15, 160, 40, 40);
  doc.text("Se o pet se perdesse, o QR Code ajudaria a encontrar", 60, 170);
  doc.text("seu tutor rapidamente, enviando uma notificação com", 60, 180);
  doc.text("a localização exata.", 60, 190);

  // Texto à esquerda e imagem 4 à direita
  doc.text("E assim, com a segurança garantida, todos viveram felizes.", 15, 220);
  doc.addImage(TagStory04, 'PNG', 60, 230, 40, 40);

  doc.setFontSize(14);
  doc.text("A segurança do seu pet está em boas mãos com VidaPet.Tech!", 15, 280);

  // Adicionar o QR Code e a explicação
  addQRCodeWithExplanation(doc, imgData);
};

// Segunda história
const generateStory2 = (doc, imgData) => {
  doc.setFontSize(12);
  doc.setTextColor(0, 0, 0);

  // Texto à esquerda e imagem à direita
  doc.text("Esta é a história de um pet que tinha uma tag mágica...", 15, 30);
  doc.addImage(TagStory02, 'PNG', 60, 40, 40, 40);

  // Imagem à esquerda e texto à direita
  doc.addImage(TagStory01, 'PNG', 15, 90, 40, 40);
  doc.text("A tag continha um QR Code que guardava informações importantes.", 60, 100);

  // Texto à esquerda e imagem à direita
  doc.text("Sempre que alguém lia o QR Code, as informações do pet eram reveladas.", 15, 160);
  doc.addImage(TagStory03, 'PNG', 60, 170, 40, 40);

  // Imagem à esquerda e texto à direita
  doc.addImage(TagStory04, 'PNG', 15, 230, 40, 40);
  doc.text("E assim, o pet vivia seguro e protegido com sua tag mágica.", 60, 240);

  // Adicionar o QR Code e a explicação
  addQRCodeWithExplanation(doc, imgData);
};

// Terceira história
const generateStory3 = (doc, imgData) => {
  doc.setFontSize(12);
  doc.setTextColor(0, 0, 0);

  // Texto à esquerda e imagem à direita
  doc.text("Um dia, um pet recebeu uma tag especial com um QR Code...", 15, 30);
  doc.addImage(TagStory03, 'PNG', 60, 40, 40, 40);

  // Imagem à esquerda e texto à direita
  doc.addImage(TagStory04, 'PNG', 15, 90, 40, 40);
  doc.text("Essa tag ajudava a encontrar o pet caso ele se perdesse.", 60, 100);

  // Texto à esquerda e imagem à direita
  doc.text("E o QR Code enviava uma notificação com a localização do pet.", 15, 160);
  doc.addImage(TagStory02, 'PNG', 60, 170, 40, 40);

  // Imagem à esquerda e texto à direita
  doc.addImage(TagStory01, 'PNG', 15, 230, 40, 40);
  doc.text("Graças ao QR Code, o pet sempre estava em segurança.", 60, 240);

  // Adicionar o QR Code e a explicação
  addQRCodeWithExplanation(doc, imgData);
};

export default generateStorytellingPDF;
