import React, { useState } from 'react';
import axios from 'axios';
import '../Painel.css'; // Certifique-se de que o caminho está correto

const AddProduct = () => {
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        price: '',
        stock: '',
        category: '',
        image: null
    });
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'image') {
            setFormData(prevData => ({ ...prevData, [name]: files[0] }));
        } else {
            setFormData(prevData => ({ ...prevData, [name]: value }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { name, description, price, stock, category, image } = formData;

        const formDataToSend = new FormData();
        formDataToSend.append('name', name);
        formDataToSend.append('description', description);
        formDataToSend.append('price', price);
        formDataToSend.append('stock', stock);
        formDataToSend.append('category', category);
        if (image) {
            formDataToSend.append('image', image);
        }

        console.log('FormData:');
        for (const [key, value] of formDataToSend.entries()) {
            console.log(`${key}: ${value}`);
        }

        try {
            const lojaId = localStorage.getItem('loja_id');
            if (!lojaId) {
                throw new Error('ID da loja não encontrado no localStorage');
            }

            formDataToSend.append('lojas_id', lojaId);

            const response = await axios.post('https://vidapet.tech/MARKET/API/add_product.php', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            console.log('Resposta do servidor:', response.data);
            
            if (response.data.success) {
                setSuccess(response.data.message);
                setFormData({
                    name: '',
                    description: '',
                    price: '',
                    stock: '',
                    category: '',
                    image: null
                });
            } else {
                setError(response.data.error);
            }
        } catch (error) {
            console.error('Erro ao adicionar produto:', error);
            setError('Erro ao adicionar produto.');
        }
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h1>Adicionar Novo Produto</h1>
                {error && <p className="error-message">{error}</p>}
                {success && <p className="success-message">{success}</p>}
                <form onSubmit={handleSubmit}>
                    <label>
                        Nome:
                        <input type="text" name="name" value={formData.name} onChange={handleChange} required />
                    </label>
                    <label>
                        Descrição:
                        <textarea name="description" value={formData.description} onChange={handleChange} required />
                    </label>
                    <label>
                        Preço:
                        <input type="number" name="price" step="0.01" value={formData.price} onChange={handleChange} required />
                    </label>
                    <label>
                        Estoque:
                        <input type="number" name="stock" value={formData.stock} onChange={handleChange} required />
                    </label>
                    <label>
                        Categoria:
                        <input type="number" name="category" value={formData.category} onChange={handleChange} required />
                    </label>
                    <label>
                        Imagem:
                        <input type="file" name="image" onChange={handleChange} />
                    </label>
                    <button type="submit">Adicionar Produto</button>
                </form>
            </div>
        </div>
    );
};

export default AddProduct;
