import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import logo from '../../../imagens/MascoteLiliVidaPetTech.png';
import HealthEventsView from './../Components/HealthEventsView'; // Importando o HealthEventsView

const PrintCarteirinhaPdf = ({ pet, onClose }) => {
    const modalRef = useRef(null);

    useEffect(() => {
        const handlePrint = () => {
            window.print();
        };

        const handleAfterPrint = () => {
            onClose(); // Fecha o modal após o print preview ser fechado
        };

        // Chamamos o print preview após a renderização do conteúdo
        const timer = setTimeout(handlePrint, 1000); // Timeout para garantir que o conteúdo esteja renderizado

        // Adicionamos o evento onafterprint para fechar o modal após a impressão
        window.addEventListener('afterprint', handleAfterPrint);

        return () => {
            clearTimeout(timer); // Limpa o timeout para evitar problemas
            window.removeEventListener('afterprint', handleAfterPrint); // Remove o listener após o uso
        };
    }, [onClose]);

    return (
        <ModalBackground>
            <ModalContent ref={modalRef}> {/* Renderiza o conteúdo aqui */}
                <Header>
                    <Logo src={logo} alt="Vida Pet" />
                    <Title>Carteira Digital Pet</Title>
                    <CloseButton onClick={onClose}>X</CloseButton>
                </Header>

                <Body>
                    <Section>
                        <PetPhoto src={pet.pet_3x4 ? `https://vidapet.tech/vidapet/pets/${pet.pet_3x4.split('/').pop()}` : logo} alt={pet.petName} />
                        <InfoText><strong>Nome:</strong> {pet.petName}</InfoText>
                        <InfoText><strong>Raça:</strong> {pet.breed}</InfoText>
                        <InfoText><strong>Idade:</strong> {pet.age} anos</InfoText>
                        <InfoText><strong>Tutor:</strong> {pet.tutor || 'Não informado'}</InfoText>
                    </Section>

                    <Section>
                        <QRCodeImage src={`https://api.qrserver.com/v1/create-qr-code/?data=https://vidapet.tech/resgate?qrcode=${pet.petId}&size=100x100`} alt="QR Code" />
                    </Section>
                </Body>

                {/* Exibe os eventos de saúde dinamicamente em formato de grade */}
                <EventsSection>
                    <h3>Eventos de Saúde</h3>
                    <SmallHealthEventsWrapper>
                        <GridView>
                            <HealthEventsView petId={pet.petId} reloadEvents={false} viewOnly={true} gridView={true} /> {/* viewOnly evita botões e gridView ativa visualização em grade */}
                        </GridView>
                    </SmallHealthEventsWrapper>
                </EventsSection>

                <Footer>
                    <p>Obrigado por usar o Vida Pet Tech!</p>
                </Footer>
            </ModalContent>
        </ModalBackground>
    );
};

// Estilos para o modal e os elementos
const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  width: 80%;  /* Alterado para 80% para melhor responsividade */
  max-width: 800px;  /* Máximo de largura para telas grandes */
  padding: 20px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media print {
    width: 100%; /* Garante que o conteúdo ocupe toda a página */
    max-width: none; /* Remove limite de largura no modo de impressão */
    margin: 0;
    padding: 10px;
    font-size: 12px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const Logo = styled.img`
  width: 60px;
`;

const Title = styled.h2`
  font-size: 18px;
  color: #333;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;

const Body = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  flex-wrap: wrap; /* Flexível para telas menores */
  @media print {
    margin-top: 10px;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PetPhoto = styled.img`
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 10px;
`;

const InfoText = styled.p`
  margin: 5px 0;
`;

const QRCodeImage = styled.img`
  width: 150px;
  height: 150px;
`;

const EventsSection = styled.div`
  width: 100%;
  margin-top: 20px;
  text-align: center;
  @media print {
    margin-top: 10px;
  }
`;

// Wrapper para aplicar estilo reduzido aos eventos
const SmallHealthEventsWrapper = styled.div`
  font-size: 12px;
  width: 100%; /* Ajusta a largura para 100% */
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Centraliza os itens */
  gap: 10px;

  img {
    width: 80px;
    height: auto;
  }

  .event-item {
    margin-bottom: 10px;
    flex-basis: 150px; /* Define a largura mínima dos itens */
  }
`;

// Grade para exibir os eventos
const GridView = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
  width: 100%; /* Garante que a grid use 100% da largura */
  justify-content: center;
`;

const Footer = styled.div`
  margin-top: 20px;
  text-align: center;
  @media print {
    margin-top: 10px;
  }
`;

export default PrintCarteirinhaPdf;
