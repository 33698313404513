import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import './Home.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Home = () => {
  const [showStory, setShowStory] = useState({
    digitalCard: false,
    telemedicine: false,
    rescue: false,
    marketplace: false,
  });

  const navigate = useNavigate();

  // Criação de referência para a seção "Como Podemos Ajudar Você no Dia a Dia"
  const featuresSectionRef = useRef(null);

  const toggleStory = (feature) => {
    setShowStory((prevState) => ({
      ...prevState,
      [feature]: !prevState[feature],
    }));
  };

  // Configurações do carrossel para o mobile
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
  };

  // Função para rolar a página para a seção "Como Podemos Ajudar Você no Dia a Dia"
  const handleLearnMoreClick = () => {
    featuresSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  // Função que redireciona para o Dashboard ao clicar no botão "Junte-se Agora"
  const handleJoinNowClick = () => {
    navigate('/dashboard');
    window.scrollTo(0, 0);  // Adiciona este comando para rolar para o topo da página
  };
  

  return (
    <div className="home-new-container">
      {/* Seção de Apresentação com Fundo Vídeo */}
      <section className="intro-section">
        <div className="background-video">
          <video autoPlay loop muted>
            <source src="/imagens/eleefon21.mp4" type="video/mp4" />
            Seu navegador não suporta vídeos.
          </video>
        </div>
        <div className="overlay-text">
          <h1>Bem-vindo ao Futuro do Cuidado com Pets</h1>
          <p>VidaPet.Tech - Tecnologia e carinho para seu pet, onde quer que você esteja.</p>
          {/* Botão atualizado para rolar até a seção de recursos */}
          <button className="cta-button" onClick={handleLearnMoreClick}>
            Conheça Nosso Ecossistema
          </button>
        </div>
      </section>

      {/* Seção de Destaque com Cartões */}
      <section className="features-section" ref={featuresSectionRef}>
        <h2>Como Podemos Ajudar Você no Dia a Dia</h2>

        {/* Desktop View */}
        <div className="desktop-view">
          <div className="features-grid">
            {/* Carteirinha Digital */}
            <div className="feature-card">
              <img src="/imagens/Produto01.jpg" alt="Carteirinha Digital" className="feature-img" />
              <h3>Carteirinha Digital</h3>
              <ul>
                <li>Mantenha o histórico de saúde do seu pet sempre atualizado e acessível.</li>
                <li>Tenha controle sobre vacinas, consultas e tratamentos com apenas um clique.</li>
                <li>Ideal para viagens e emergências: todos os dados do seu pet no seu bolso.</li>
                <li>Compatível com todos os tipos de pets e fácil de usar por qualquer tutor.</li>
                <li>Grátis</li>

              </ul>
              <button className="saiba-mais-btn" onClick={() => toggleStory('digitalCard')}>
                {showStory.digitalCard ? 'Ocultar História' : 'Saiba Mais'}
              </button>
              {showStory.digitalCard && (
                <div className="storytelling">
                  <h4>História Completa</h4>
                  <ul>
                    <li>Você adotou um cachorro chamado Tobby e começou a acumular vários documentos e exames veterinários.</li>
                    <li>Com a Carteirinha Digital da VidaPet.Tech, tudo é armazenado digitalmente, e basta abrir o app para acessar o histórico completo.</li>
                    <li>Se você estiver viajando e algo acontecer com Tobby, qualquer veterinário pode acessar esses dados e garantir um atendimento rápido e eficiente.</li>
                  </ul>

                  {/* Exibe as imagens Carteirinha01 a Carteirinha05 */}
                  <div className="carteirinha-images">
                    <img src="/imagens/Carteira01.jpeg" alt="Carteirinha 01" className="carteirinha-img" />
                    <img src="/imagens/Carteira02.jpeg" alt="Carteirinha 02" className="carteirinha-img" />
                    <img src="/imagens/Carteira03.jpeg" alt="Carteirinha 03" className="carteirinha-img" />
                    <img src="/imagens/Carteira05.jpeg" alt="Carteirinha 04" className="carteirinha-img" />
                    <img src="/imagens/Carteira04.jpeg" alt="Carteirinha 05" className="carteirinha-img" />
                  </div>
                </div>
              )}
            </div>

            {/* Telemedicina Veterinária */}
            <div className="feature-card">
              <img src="/imagens/Produto02.jpg" alt="Telemedicina Veterinária" className="feature-img" />
              <h3>Telemedicina Veterinária</h3>
              <p style={{ color: 'red' }}>Em breve esse serviço estará disponível.</p>
              <ul>
                <li>Consulta veterinária online, 24 horas por dia, 7 dias por semana.</li>
                <li>Tenha acesso imediato a um veterinário sem sair de casa, mesmo em emergências.</li>
              </ul>
              <button className="saiba-mais-btn" onClick={() => toggleStory('telemedicine')}>
                {showStory.telemedicine ? 'Ocultar História' : 'Saiba Mais'}
              </button>
              {showStory.telemedicine && (
                <div className="storytelling">
                  <h4>História Completa</h4>
                  <ul>
                    <li>Luna, sua gatinha, começa a agir de forma estranha e está inquieta durante a noite.</li>
                    <li>Com a telemedicina VidaPet.Tech, em minutos você fala com um veterinário online, que acalma você e te orienta sobre os cuidados emergenciais.</li>
                  </ul>
                </div>
              )}
            </div>

            {/* Marketplace para Pets (Troca com Resgate Inteligente) */}
            <div className="feature-card">
              <img src="/imagens/Produto03.jpg" alt="Marketplace para Pets" className="feature-img" />
              <h3>Marketplace para Pets</h3>
              <p style={{ color: 'red' }}>Em breve esse serviço estará disponível.</p>
              <ul>
                <li>Produtos de alta qualidade para seu pet com entrega rápida.</li>
              </ul>
              <button className="saiba-mais-btn" onClick={() => toggleStory('marketplace')}>
                {showStory.marketplace ? 'Ocultar História' : 'Saiba Mais'}
              </button>
              {showStory.marketplace && (
                <div className="storytelling">
                  <h4>História Completa</h4>
                  <ul>
                    <li>Você está procurando uma ração específica para seu pet, mas não consegue encontrá-la nas lojas perto de você.</li>
                    <li>Com o Marketplace VidaPet.Tech, você encontra rapidamente a ração que precisa e faz o pedido online, sem complicações.</li>
                  </ul>
                </div>
              )}
            </div>

            {/* Resgate Inteligente (Troca com Marketplace para Pets) */}
            <div className="feature-card">
              <img src="/imagens/Produto04.webp" alt="Resgate Inteligente" className="feature-img" />
              <h3>Resgate Inteligente</h3>
              <ul>
                <li>Geolocalização e alerta instantâneo para encontrar pets perdidos.</li>
              </ul>
              <button className="saiba-mais-btn" onClick={() => toggleStory('rescue')}>
                {showStory.rescue ? 'Ocultar História' : 'Saiba Mais'}
              </button>
              {showStory.rescue && (
                <div className="storytelling">
                  <h4>História Completa</h4>
                  <ul>
                    <li>Você está passeando com Rex no parque quando, de repente, ele desaparece.</li>
                    <li>Com o Resgate Inteligente, você ativa um alerta e outros usuários ao redor são notificados.</li>
                    <li>Graças à geolocalização, Rex é encontrado rapidamente em uma rua próxima.</li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>

              {/* Carrossel para Mobile */}
        {/* Carrossel para Mobile */}
        <div className="mobile-view">
          <Slider {...sliderSettings}>
            {/* Carteirinha Digital */}
            <div className="feature-card">
              <img src="/imagens/Produto01.webp" alt="Carteirinha Digital" className="feature-img" />
              <h3>Carteirinha Digital</h3>
              <ul>
                <li>Mantenha o histórico de saúde do seu pet sempre atualizado e acessível.</li>
                <li>Tenha controle sobre vacinas, consultas e tratamentos com apenas um clique.</li>
              </ul>
              <button className="saiba-mais-btn" onClick={() => toggleStory('digitalCard')}>
                {showStory.digitalCard ? 'Ocultar História' : 'Saiba Mais'}
              </button>
              {showStory.digitalCard && (
                <div className="storytelling">
                  <h4>História Completa</h4>
                  <ul>
                    <li>Você adotou um cachorro chamado Tobby e começou a acumular vários documentos e exames veterinários.</li>
                    <li>Com a Carteirinha Digital da VidaPet.Tech, tudo é armazenado digitalmente, e basta abrir o app para acessar o histórico completo.</li>
                    <li>Se você estiver viajando e algo acontecer com Tobby, qualquer veterinário pode acessar esses dados e garantir um atendimento rápido e eficiente.</li>
                  </ul>
                  {/* Exibe as imagens Carteirinha01 a Carteirinha05 */}
                  <div className="carteirinha-images">
                    <img src="/imagens/Carteira01.jpeg" alt="Carteirinha 01" className="carteirinha-img" />
                    <img src="/imagens/Carteira02.jpeg" alt="Carteirinha 02" className="carteirinha-img" />
                    <img src="/imagens/Carteira03.jpeg" alt="Carteirinha 03" className="carteirinha-img" />
                    <img src="/imagens/Carteira05.jpeg" alt="Carteirinha 04" className="carteirinha-img" />
                    <img src="/imagens/Carteira04.jpeg" alt="Carteirinha 05" className="carteirinha-img" />
                  </div>
                </div>
              )}
            </div>

            {/* Telemedicina Veterinária */}
            <div className="feature-card">
              <img src="/imagens/Produto02.webp" alt="Telemedicina Veterinária" className="feature-img" />
              <h3>Telemedicina Veterinária</h3>
              <p style={{ color: 'red' }}>Em breve esse serviço estará disponível.</p>
              <ul>
                <li>Consulta veterinária online, 24 horas por dia, 7 dias por semana.</li>
                <li>Tenha acesso imediato a um veterinário sem sair de casa, mesmo em emergências.</li>
              </ul>
              <button className="saiba-mais-btn" onClick={() => toggleStory('telemedicine')}>
                {showStory.telemedicine ? 'Ocultar História' : 'Saiba Mais'}
              </button>
              {showStory.telemedicine && (
                <div className="storytelling">
                  <h4>História Completa</h4>
                  <ul>
                    <li>Luna, sua gatinha, começa a agir de forma estranha e está inquieta durante a noite.</li>
                    <li>Com a telemedicina VidaPet.Tech, em minutos você fala com um veterinário online, que acalma você e te orienta sobre os cuidados emergenciais.</li>
                  </ul>
                </div>
              )}
            </div>

            {/* Marketplace para Pets (Troca com Resgate Inteligente) */}
            <div className="feature-card">
              <img src="/imagens/Produto03.webp" alt="Marketplace para Pets" className="feature-img" />
              <h3>Marketplace para Pets</h3>
              <p style={{ color: 'red' }}>Em breve esse serviço estará disponível.</p>
              <ul>
                <li>Produtos de alta qualidade para seu pet com entrega rápida.</li>
              </ul>
              <button className="saiba-mais-btn" onClick={() => toggleStory('marketplace')}>
                {showStory.marketplace ? 'Ocultar História' : 'Saiba Mais'}
              </button>
              {showStory.marketplace && (
                <div className="storytelling">
                  <h4>História Completa</h4>
                  <ul>
                    <li>Você está procurando uma ração específica para seu pet, mas não consegue encontrá-la nas lojas perto de você.</li>
                    <li>Com o Marketplace VidaPet.Tech, você encontra rapidamente a ração que precisa e faz o pedido online, sem complicações.</li>
                  </ul>
                </div>
              )}
            </div>

            {/* Resgate Inteligente (Troca com Marketplace para Pets) */}
            <div className="feature-card">
              <img src="/imagens/Produto04.webp" alt="Resgate Inteligente" className="feature-img" />
              <h3>Resgate Inteligente</h3>
              <ul>
                <li>Geolocalização e alerta instantâneo para encontrar pets perdidos.</li>
              </ul>
              <button className="saiba-mais-btn" onClick={() => toggleStory('rescue')}>
                {showStory.rescue ? 'Ocultar História' : 'Saiba Mais'}
              </button>
              {showStory.rescue && (
                <div className="storytelling">
                  <h4>História Completa</h4>
                  <ul>
                    <li>Você está passeando com Rex no parque quando, de repente, ele desaparece.</li>
                    <li>Com o Resgate Inteligente, você ativa um alerta e outros usuários ao redor são notificados.</li>
                    <li>Graças à geolocalização, Rex é encontrado rapidamente em uma rua próxima.</li>
                  </ul>
                </div>
              )}
            </div>
          </Slider>
        </div>

      </section>

      {/* Botão "Junte-se Agora" antes dos Depoimentos */}
      <section className="cta-final-section">
        <button className="cta-button" onClick={handleJoinNowClick}>Cadastre-se Grátis e Começe Agora!</button>
      </section>

      {/* Seção de Testemunhos */}
      <section className="testimonials-section">
        <h2>O Que Nossos Usuários Dizem</h2>
        <div className="testimonials-container">
          <div className="testimonial">
            <p>"Fui o primeiro usuário VidaPet.Tech e uso com minha YorkShire, Jade. Tudo está guardado lá."</p>
            <span>- Anderson Santos</span>
          </div>
          <div className="testimonial">
            <p>"O resgate interativo salvou a vida do meu cachorro, sou eternamente grata ao VidaPet.Tech!"</p>
            <span>- Lucas, tutor do Max</span>
          </div>
        </div>
      </section>

      {/* Seção de Chamada Final */}
      <section className="cta-final-section">
        <h2>Pronto para Transformar o Cuidado com Seu Pet?</h2>
        <p>
          VidaPet.Tech traz para você tecnologia que facilita o cuidado e o bem-estar do seu animal. Organize a saúde, tenha sempre um veterinário à disposição e encontre seu pet em caso de emergência. Tudo isso em um único lugar.
        </p>
        <button className="cta-button small-btn" onClick={handleJoinNowClick}>Junte-se Agora!</button>
      </section>
    </div>
  );
};

export default Home;
