import React, { useState } from 'react';
import styled from 'styled-components';
import { auth } from '../../firebase';
import { RiCloseCircleFill } from "react-icons/ri";

const EsqueciSenha = ({ closeEsqueciSenha, openConfirmSenha, openLogin }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleResetPassword = async (e) => {
    e.preventDefault(); 
    try {
      await auth.sendPasswordResetEmail(email);
      setMessage('Email enviado com sucesso.');
      closeEsqueciSenha();
      openConfirmSenha(); 
    } catch (error) {
      setMessage('Parece que o endereço de email inserido não está correto. Por favor, verifique e tente novamente.');
    }
  };

  const close = () => {
    closeEsqueciSenha();
    openLogin();
  };

  return (
    <Cont>
      <div className='wrapper'>
        <RiCloseCircleFill size={35} className='close' onClick={close} />
        <h1>Esqueceu a senha?</h1>
        <p>Informe seu e-mail cadastrado no Vida Pet para enviarmos as instruções de redefinição da senha.</p>
        <form onSubmit={handleResetPassword}>
          <input
            type='email'
            placeholder='Email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type='submit' className='buttonEntrar'>Enviar Email de Recuperação</button>
        </form>
        <div className='message'>
          {message && <p>{message}</p>}
        </div>
      </div>
    </Cont>
  );
};

export default EsqueciSenha;

const Cont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;

  .wrapper {
    background: white;
    padding: 20px;
    width: 90%;
    max-width: 400px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

    h1 {
      text-align: center;
      color: #333;
    }

    input, button {
      width: 100%;
      padding: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
      border-radius: 4px;
      border: 1px solid #ccc;
    }

    button {
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: #0056b3;
      }
    }

    .close {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
      color: #666;

      &:hover {
        color: #222;
      }
    }
  }
`;
