import React from 'react';

const ProductDetails = ({ product }) => {
  if (!product) return null;

  return (
    <div className="product-info">
      <h1>{product.name}</h1>
      <p>Estoque: {product.stock}</p>
      <p>Preço: R${parseFloat(product.price).toFixed(2)}</p>
      <button className="buy-button">Comprar</button>
      <button className="add-to-cart-button">Adicionar ao Carrinho</button>

      <div className="social-share">
        <a href="#" className="social-icon" aria-label="Compartilhar">🔗</a>
        <a href="#" className="social-icon" aria-label="Facebook">📘</a>
        <a href="#" className="social-icon" aria-label="Twitter">🐦</a>
      </div>
    </div>
  );
};

export default ProductDetails;
