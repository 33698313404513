import React from 'react';

const Orders = () => {
    return (
        <div>
            <h2>Resumo da Orders</h2>
            {/* Adicione aqui o conteúdo do resumo */}
        </div>
    );
};

export default Orders;
