import React, { useState } from 'react';
import './ProductDetail.css'; // Importar o arquivo CSS para estilos

const ProductDetailsTabs = ({ description, technicalInfo, packagingContent }) => {
  const [activeTab, setActiveTab] = useState('description');

  const renderContent = () => {
    switch (activeTab) {
      case 'description':
        return <p>{description}</p>;
      case 'technicalInfo':
        return <p>{technicalInfo}</p>;
      case 'packagingContent':
        return <p>{packagingContent}</p>;
      default:
        return <p>{description}</p>;
    }
  };

  return (
    <div className="product-details-tabs">
      <div className="tabs">
        <button 
          className={`tab ${activeTab === 'description' ? 'active' : ''}`} 
          onClick={() => setActiveTab('description')}
        >
          Descrição
        </button>
        <button 
          className={`tab ${activeTab === 'technicalInfo' ? 'active' : ''}`} 
          onClick={() => setActiveTab('technicalInfo')}
        >
          Visão Técnica
        </button>
        <button 
          className={`tab ${activeTab === 'packagingContent' ? 'active' : ''}`} 
          onClick={() => setActiveTab('packagingContent')}
        >
          Conteúdo da Embalagem
        </button>
      </div>
      <div className="tab-content">
        {renderContent()}
      </div>
    </div>
  );
};

export default ProductDetailsTabs;
