import React from 'react';

const Inventory = () => {
    return (
        <div>
            <h2>Resumo da Inventory</h2>
            {/* Adicione aqui o conteúdo do resumo */}
        </div>
    );
};

export default Inventory;
