import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import './VetCall.css'; // Certifique-se de importar o CSS aqui também

function VetList({ onSelectRoom }) {
  const [rooms, setRooms] = useState([]);
  const [error, setError] = useState(null);

  const fetchRooms = useCallback(async () => {
    try {
      const response = await axios.get('https://vidapet.tech/api/calls/get_open_rooms.php');
      console.log('Resposta da API:', response.data);
      setRooms(response.data);
    } catch (error) {
      console.error('Erro ao buscar salas:', error);
      setError(error.message);
    }
  }, []);

  useEffect(() => {
    fetchRooms();
  }, [fetchRooms]);

  const handleRoomClick = (room) => {
    if (onSelectRoom) {
      onSelectRoom(room.room_name);
    }
  };

  if (error) {
    return <div>Erro ao carregar salas: {error}</div>;
  }

  return (
    <div className="vet-list">
      <h1>Salas Pendentes</h1>
      <ul>
        {Array.isArray(rooms) && rooms.length > 0 ? (
          rooms.map((room) => (
            <li key={room.room_name}>
              Sala: {room.room_name} - Veterinário: {room.vet_id}
              <button onClick={() => handleRoomClick(room)}>Selecionar</button>
            </li>
          ))
        ) : (
          <li>Nenhuma sala pendente no momento.</li>
        )}
      </ul>
    </div>
  );
}

export default VetList;
