import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { IoMdCloseCircle } from 'react-icons/io';
import HealthEventsModal from './HealthEventsModal';
import VaccineModal from './VaccineModal';
import petIcon from '../../../imagens/MascoteLiliVidaPetTech.png';
import logo from '../../../imagens/MascoteLiliVidaPetTech.png';
import PrintQRCodeButton from './PrintQRCodeButton'; // Importa o botão de impressão de QR Code
import PrintCarteirinhaPdf from './PrintCarteirinhaPdf'; // Importa o componente de impressão de carteirinha
import { QRCodeCanvas } from 'qrcode.react'; // Substitui QRCode por QRCodeCanvas

const Carteirinha = ({ pet, onClose }) => {
  const [nomeTutor, setNomeTutor] = useState('');
  const [telefoneTutor, setTelefoneTutor] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [showHealthEventsModal, setShowHealthEventsModal] = useState(false);
  const [showVaccineModal, setShowVaccineModal] = useState(false);
  const [selectedVaccine, setSelectedVaccine] = useState(null);
  const [appliedVaccines, setAppliedVaccines] = useState([]); // Certifique-se de que está definido corretamente
  const [loading, setLoading] = useState(false);
  const [showPrintModal, setShowPrintModal] = useState(false); // Estado para controlar o modal de impressão
  const [petImage, setPetImage] = useState(pet.pet_3x4 ? `https://vidapet.tech/vidapet/pets/${pet.pet_3x4.split('/').pop()}` : petIcon);

  const qrCodeRef = useRef(); // Usado para referenciar a tag do QR Code

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://vidapet.tech/api_carteira_pet.php?petId=${pet.petId || pet.id}`);
        if (!response.ok) throw new Error('Erro ao buscar dados da carteirinha');
        const responseData = await response.json();
        setNomeTutor(responseData.observacao01 || 'Tutor');
        setTelefoneTutor(responseData.observacao02 || 'Telefone');

        const vaccinesResponse = await fetch(`https://vidapet.tech/vaccines.php?petId=${pet.petId}`);
        const vaccinesData = await vaccinesResponse.json();
        setAppliedVaccines(vaccinesData);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
    };
    fetchData();
  }, [pet]);

  const handleSaveTutorInfo = async () => {
    try {
      const response = await fetch('https://vidapet.tech/api_carteira_pet.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          petId: pet.petId || pet.id,
          nomePet: pet.petName,
          tipo: pet.Type || "", 
          raca: pet.breed || "",
          tutorUserId: pet.userId || "", 
          observacao01: nomeTutor, 
          observacao02: telefoneTutor,
          observacao03: "" 
        }),
      });

      if (!response.ok) {
        const responseData = await response.json();
        throw new Error(`Erro da API: ${responseData.error}`);
      }

      // Sucesso ao salvar as informações
      alert('Informações salvas com sucesso!');

      // Atualiza as informações exibidas no componente com os dados mais recentes da API
      const updatedResponse = await fetch(`https://vidapet.tech/api_carteira_pet.php?petId=${pet.petId || pet.id}`);
      const updatedData = await updatedResponse.json();

      setNomeTutor(updatedData.observacao01 || 'Tutor');
      setTelefoneTutor(updatedData.observacao02 || 'Telefone');
      setEditMode(false); // Sai do modo de edição
    } catch (error) {
      console.error('Erro ao salvar informações do tutor:', error);
    }
  };

  // Adicionar lógica para abrir o modal de vacina
  const handleVaccineClick = (vaccine) => {
    setSelectedVaccine(vaccine); // Definir a vacina selecionada
    setShowVaccineModal(true); // Mostrar o modal de vacinação
  };

  const handlePrintPDF = () => {
    setShowPrintModal(true); // Ativa o modal de impressão quando o botão é clicado
  };

  return (
    <ModalBackground>
      <ModalContent>
        <Header>
          <Logo src={logo} alt="Vida Pet" />
          <Title>Carteira Digital Pet</Title>
          <CloseButton onClick={onClose} />
        </Header>

        <FlexContainer>
          <Section>
            <PetPhoto src={petImage} alt={pet.petName} />
            <InfoText><strong>{pet.petName}</strong></InfoText>
            <InfoText><strong>Raça:</strong> {pet.breed}</InfoText>
            <InfoText><strong>Idade:</strong> {pet.age} anos</InfoText>
            <InfoText><strong>Tutor:</strong> {nomeTutor}</InfoText>
            <InfoText><strong>Telefone:</strong> {telefoneTutor}</InfoText>
            {editMode ? (
              <>
                <input type="text" value={nomeTutor} onChange={(e) => setNomeTutor(e.target.value)} />
                <input type="text" value={telefoneTutor} onChange={(e) => setTelefoneTutor(e.target.value)} />
                <Button onClick={handleSaveTutorInfo}>Salvar Informações</Button>
              </>
            ) : (
              <Button onClick={() => setEditMode(true)}>Editar Tutor ou Telefone</Button>
            )}
          </Section>

          <Section ref={qrCodeRef}>
            <QRCodeCanvas value={`https://vidapet.tech/resgate?qrcode=${pet.petId}`} size={100} />
            <VaccinesContainer>
              <h4>Vacinas</h4>
              <VaccineGrid>
                {['V8', 'V10', 'Antirrábica'].map((vaccine) => (
                  <VaccineIcon key={vaccine} onClick={() => handleVaccineClick(vaccine)}>
                    {vaccine}
                  </VaccineIcon>
                ))}
              </VaccineGrid>
            </VaccinesContainer>
          </Section>
        </FlexContainer>

        <ButtonContainer>
          <Button onClick={() => setShowHealthEventsModal(true)}>Eventos de Saúde</Button>
          <Button onClick={handlePrintPDF}>Carteirinha em PDF</Button>

          {/* Integrando o PrintQRCodeButton */}
          <PrintQRCodeButton 
            qrCodeUrl={`https://vidapet.tech/resgate?qrcode=${pet.petId}`} 
            className="qr-print-button" 
            petId={pet.petId}  // Certifique-se de que petId está sendo passado
          />
        </ButtonContainer>

        {showHealthEventsModal && (
          <HealthEventsModal
            petId={pet.petId}
            onClose={() => setShowHealthEventsModal(false)}
          />
        )}

        {/* Modal de Vacinas */}
        {showVaccineModal && (
          <VaccineModal
            vaccine={selectedVaccine} // Passa a vacina selecionada para o modal
            petId={pet.petId}
            onClose={() => setShowVaccineModal(false)}
          />
        )}

        {loading && <LoadingMessage>Gerando PDF...</LoadingMessage>}

        {/* O modal de impressão é mostrado assim que o botão é clicado */}
        {showPrintModal && (
          <PrintCarteirinhaPdf
            pet={pet}
            onClose={() => setShowPrintModal(false)} // Fecha o modal de impressão
          />
        )}
      </ModalContent>
    </ModalBackground>
  );
};


// Componentes de estilo (mesmos que você já tinha definido)
const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column; // Coloca as seções uma embaixo da outra em telas menores

  @media (min-width: 768px) {
    flex-direction: row; // Em telas maiores, mantém as seções lado a lado
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0f4f8;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: white;
  border-radius: 15px;
  width: 100%;
  max-width: 400px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);

  @media (min-width: 768px) {
    max-width: 600px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Logo = styled.img`
  width: 40px;
`;

const Title = styled.h2`
  font-size: 16px;
  color: #333;
`;

const CloseButton = styled(IoMdCloseCircle)`
  font-size: 22px;
  cursor: pointer;
`;

const PetPhoto = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    width: 100px;
    height: 100px;
  }
`;

const InfoText = styled.p`
  font-size: 15px;
  margin: 2px 0;
  text-align: left;
  width: 100%;
`;

const VaccinesContainer = styled.div`
  margin-top: 15px;
  width: 100%;
  background-color: #e8eef3;
  border-radius: 8px;
  padding: 10px;
`;

const VaccineGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
`;

const VaccineIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px;
  background-color: red;
  color: white;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  font-size: 10px;
  width: 40px;
  height: 30px;

  @media (min-width: 768px) {
    width: 50px;
    height: 40px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 10px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  padding: 8px 10px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 12px;
  margin-bottom: 10px;

  &:hover {
    background-color: #0056b3;
  }

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

const LoadingMessage = styled.p`
  color: #666;
  font-size: 12px;
  margin-top: 10px;
`;

export default Carteirinha;
