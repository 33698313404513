import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import pinIcon from '../../imagens/icon-Map.png';
import userLocationIconImage from '../../imagens/user-location-icon.png';
import Filter from './Filter';
import { Container, MapContainerWrapper, MapExpandButton, RescueList, RescueCard, RescueImage, CardContent, CardTitle, Comments, ButtonRe, ActionButtonWrapper, PetActionButton, ShareButtonsWrapper, FacebookShareButton, WhatsAppShareButton, FollowButton, RescueStatusOverlay, ImageModal, ModalImage } from './Components/CSS/styleRescue';
import { TbMapShare } from "react-icons/tb";
import { Link, useNavigate } from 'react-router-dom';
import { FaArrowCircleRight } from "react-icons/fa";

const RescueView = () => {
  const [rescueData, setRescueData] = useState([]);
  const [filteredRescueData, setFilteredRescueData] = useState([]);
  const [isMapExpanded, setIsMapExpanded] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const [rescueStatus, setRescueStatus] = useState([]);
  const [expandedImage, setExpandedImage] = useState(null);
  const [distance, setDistance] = useState(10);
  const navigate = useNavigate();

  useEffect(() => {
    fetch('https://vidapet.tech/rescue_list.php')
      .then(response => response.json())
      .then(data => {
        setRescueData(data);
        setFilteredRescueData(data);
        setRescueStatus(new Array(data.length).fill(''));
      })
      .catch(error => console.error('Erro ao buscar dados:', error));

    navigator.geolocation.getCurrentPosition(
      (position) => {
        setUserLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      (error) => {
        console.error('Erro ao obter a localização:', error);
      }
    );
  }, []);

  const handlePetFound = (id) => {
    updatePetStatus(id, 'encontrado');
  };

  const handlePetRescued = (id) => {
    updatePetStatus(id, 'resgatado');
  };

  const updatePetStatus = (id, status) => {
    fetch('https://vidapet.tech/rescue_status.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id, status })
    })
      .then(response => response.json())
      .then(data => {
        setRescueData(data);
        const updatedRescueStatus = [...rescueStatus];
        updatedRescueStatus[id] = status;
        setRescueStatus(updatedRescueStatus);
        window.alert(`Pet marcado como ${status}.`);
      })
      .catch(error => {
        console.error('Erro ao atualizar o status:', error);
        window.alert('Erro ao atualizar o status do pet.');
      });
  };

  const handleFollow = (id) => {
    console.log('ID recebido para acompanhamento:', id);

    const path = `/rescues/${id}`;
    console.log('Navegando para o caminho:', path);

    navigate(path);
  };



  const handleFollowCase = (idCapturado) => {
    const userId = localStorage.getItem('userId');
    const confirmFollow = window.confirm('Deseja acompanhar este caso?');

    if (confirmFollow) {
      const requestData = {
        idCapturado,
        status: 'Acompanhado',
        userCreator: userId
      };

      fetch('https://vidapet.tech/crud-casosderesgate.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData)
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            window.alert('Você está acompanhando este caso.');
            const updatedRescueData = rescueData.map(rescue => {
              if (rescue.id === idCapturado) {
                return { ...rescue, status: 'Acompanhado', acompanhando: [...rescue.acompanhando, userId] };
              }
              return rescue;
            });
            setRescueData(updatedRescueData);

            // Navegue para a página de detalhes do resgate
            navigate(`/rescues/${idCapturado}`);
          } else {
            window.alert('Erro ao acompanhar caso.');
          }
        })
        .catch(error => {
          console.error('Erro ao acompanhar caso:', error);
        });
    }
  };



  const icon = L.icon({
    iconUrl: pinIcon,
    iconSize: [38, 38],
    iconAnchor: [19, 38]
  });

  const userLocationIcon = L.icon({
    iconUrl: userLocationIconImage,
    iconSize: [38, 38],
    iconAnchor: [19, 38]
  });

  const handleFilterChange = (filteredData) => {
    setFilteredRescueData(filteredData);
  };

  const handleMapToggle = () => {
    setIsMapExpanded(!isMapExpanded);
  };

  const openImage = (imageUrl) => {
    setExpandedImage(imageUrl);
  };

  const closeImage = () => {
    setExpandedImage(null);
  };

  return (
    <Container>
      <h1>Mapa de Resgates</h1>

      <div className='cont'>
        <Filter rescueData={rescueData} onFilterChange={handleFilterChange} />

        <MapContainerWrapper isExpanded={isMapExpanded}>
          <MapContainer center={userLocation || [-22.933754, -42.894540]} zoom={9} scrollWheelZoom={true} style={{ height: '100%', width: '100%' }}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            {userLocation && (
              <Marker position={[userLocation.lat, userLocation.lng]} icon={userLocationIcon}>
                <Popup>Sua localização</Popup>
              </Marker>
            )}
            {filteredRescueData.map((rescue) => (
              <Marker
                key={rescue.id}
                position={[rescue.latitude, rescue.longitude]}
                icon={icon}
              >
                <Popup>
                  <div className="PopupConten">
                    <RescueImage
                      src={`https://vidapet.tech/${rescue.image_path}`}
                      alt={`Resgate ${rescue.type}`}
                    />

                    <div className="ActionButtonWrapper">
                    <FollowButton onClick={() => handleFollow(rescue.id)}>Acompanhar Caso
                    
                    </FollowButton>
                    </div>

                  </div>

                </Popup>
              </Marker>
            ))}
          </MapContainer>

          <MapExpandButton onClick={handleMapToggle}>
            {isMapExpanded ? 'Reduzir Mapa' : 'Expandir Mapa'}
          </MapExpandButton>
        </MapContainerWrapper>
      </div>

      <h2>Lista de Resgates</h2>

      <RescueList>
        {filteredRescueData.map((rescue) => (
          <RescueCard key={rescue.id}>
            <Link to={`/rescues/${rescue.id}`}>
        <RescueImage
          src={`https://vidapet.tech/${rescue.image_path}`}
          alt={`Resgate ${rescue.pet_type}`}
        />
      </Link>
            <CardContent>
              <CardTitle>{rescue.pet_type}</CardTitle>
              <Comments>{rescue.comments}</Comments>

              
              <ButtonRe onClick={() => window.open(`https://www.google.com/maps?q=${rescue.latitude},${rescue.longitude}`, '_blank')}>
              <TbMapShare />   Ver no Mapa
              </ButtonRe>  
                                      
              <ButtonRe onClick={() => handleFollow(rescue.id)}>Acompanhar Caso
              <FaArrowCircleRight />
              </ButtonRe>

             

              {rescue.captured_photos && rescue.captured_photos.length > 0 && (
                <RescueStatusOverlay>
                  {rescueStatus[rescue.captured_photos[0].id]?.charAt(0).toUpperCase() + rescueStatus[rescue.captured_photos[0].id]?.slice(1)}
                </RescueStatusOverlay>
              )}
            </CardContent>
          </RescueCard>
        ))}
      </RescueList>

      {expandedImage && (
        <ImageModal onClick={closeImage}>
          <ModalImage src={expandedImage} onClick={(e) => e.stopPropagation()} />
        </ImageModal>
      )}
    </Container>
  );
};

export default RescueView;
